import React from 'react';

function MicrosoftLogoAuth() {
  return (
    <svg
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.0977 15.3491V25.7659L40.7417 28.0577C40.8733 28.0867 41.0096 28.0867 41.1411 28.0577L56.8091 17.4945C56.7977 16.9818 56.6101 16.4887 56.278 16.098C55.9458 15.7074 55.4893 15.4429 54.9852 15.3491H37.0977Z"
        fill="#0072C6"
      />
      <path
        d="M37.0976 29.6513L40.4203 31.9336C40.5758 32.0283 40.7545 32.0784 40.9366 32.0784C41.1188 32.0784 41.2974 32.0283 41.453 31.9336C40.8824 32.2778 56.8072 21.7051 56.8072 21.7051V40.8498C56.8487 41.2377 56.805 41.63 56.679 41.9993C56.5531 42.3686 56.348 42.7058 56.078 42.9875C55.8081 43.2692 55.4799 43.4886 55.1163 43.6302C54.7528 43.7717 54.3627 43.8322 53.9733 43.8072H37.0957V29.6513H37.0976Z"
        fill="#0072C6"
      />
      <path
        d="M19.8948 24.8502C19.3427 24.8475 18.8002 24.9943 18.3247 25.2749C17.8493 25.5555 17.4586 25.9594 17.194 26.444C16.4715 27.7285 16.1249 29.1903 16.1936 30.6625C16.1185 32.1316 16.4657 33.5914 17.194 34.8695C17.4575 35.3341 17.8386 35.7212 18.299 35.9919C18.7594 36.2626 19.283 36.4073 19.8171 36.4116C20.3512 36.4158 20.877 36.2794 21.3416 36.0161C21.8063 35.7527 22.1935 35.3717 22.4643 34.9113C23.1847 33.6392 23.5246 32.1869 23.4437 30.7271C23.5262 29.2215 23.1975 27.7219 22.4928 26.3889C22.2409 25.9201 21.8658 25.529 21.408 25.2578C20.9501 24.9867 20.4269 24.8457 19.8948 24.8502Z"
        fill="#0072C6"
      />
      <path
        d="M4.13477 10.0582V50.8105L35.136 57.3113V4.05762L4.13477 10.0582ZM24.8809 37.324C24.3009 38.1416 23.5298 38.8049 22.6348 39.2561C21.7397 39.7072 20.7478 39.9326 19.7457 39.9125C18.7688 39.9299 17.8021 39.7111 16.9279 39.2746C16.0537 38.8382 15.2979 38.1971 14.7246 37.4058C13.3651 35.5077 12.685 33.2068 12.7942 30.8746C12.6785 28.4281 13.3709 26.0114 14.7646 23.9973C15.3518 23.1641 16.135 22.4882 17.0452 22.0292C17.9553 21.5702 18.9644 21.3421 19.9834 21.3651C20.9533 21.3456 21.9131 21.5645 22.7786 22.0026C23.6441 22.4406 24.3888 23.0844 24.9474 23.8775C26.2934 25.8168 26.9619 28.1459 26.8494 30.5038C26.9679 32.9326 26.2754 35.3319 24.8809 37.324Z"
        fill="#0072C6"
      />
    </svg>
  );
}

export default MicrosoftLogoAuth;
