import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import '../css/dashBoard.css';
import '../css/settings.css';
import {
  getAvailableSlot,
  getDefaultMeetingSettings,
  getSSODetails,
  googleSSO,
  saveAuthToken,
  saveGoogleAuthToken,
} from '../modals';
import GoogleLogoAuth from '../components/svg/googleLogo';
import MicrosoftLogoAuth from '../components/svg/microsoftLogo';
import UpdateMeetingSettingsAuth from '../components/svg/updateMeetingSettingsIcon';
import UpdateCalendarAvailabilityAuth from '../components/svg/updateCalendarAvailability';
import DefaultMeetingSettingsModal from '../dashBoard/modal/defaultMeetingSettingsModal';
import CalendarModal from '../calendar/modal/calendarModal';
import { format, subDays, addDays } from 'date-fns';

function DashBoard() {
  const USER_INFO = { ...JSON.parse(localStorage.getItem('userinfo')) };
  // USER_INFO.isFirstTimeLogin = 0;
  const { isFirstTimeLogin, schoolId, id } = USER_INFO;
  const location = useLocation();
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showDefaultMeetingModal, setShowDefaultMeetingModal] = useState(false);
  const [saveAuthLoading, setSaveAuthLoading] = useState(false);
  const [ssoDetails, setSsoDetails] = useState({});
  const {
    isPreviouslyAthenticated = '',
    url = '',
    isSsoLoading = false,
  } = ssoDetails;
  const [errorMsg, setErrorMsg] = useState({});
  const { googleEror = '', outlookError = '' } = errorMsg;
  const [googleSsoDetails, setGoogleSsoDetails] = useState({});
  const {
    url: gUrl = '',
    isPreviouslyAuthenticated = '',
    isGoogleSsoLoading = false,
  } = googleSsoDetails;
  const [googleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [requireMeetingSettings, setRequireMeetingSettings] = useState(false);
  const { message = '', showMsg = false } = requireMeetingSettings;
  const [noSlotsAvailable, setNoSlotsAvailable] = useState(true);
  const [calendarProps, setCalendarProps] = useState({});
  const {
    editable = false,
    navigationAllowed = false,
    firstTime = false,
    viewOnly = false,
    skipWeeks = false,
  } = calendarProps;

  const showHideDefaultMeetingModal = (bool) => {
    setShowDefaultMeetingModal(bool);
    if (!bool && noSlotsAvailable) {
      loadDefaultMeetingSettings();
    }
  };
  const showHideCalendarModal = (bool) => {
    setShowCalendarModal(bool);
    if (!bool && noSlotsAvailable) {
      loadAvailableSlot();
    }
  };

  const loadSSODetails = async () => {
    setSsoDetails({ ...ssoDetails, isSsoLoading: true });
    try {
      const result = await getSSODetails();
      setSsoDetails({ ...result, isSsoLoading: false });
    } catch (error) {
      console.log('sso deatials', error);
      setSsoDetails({ ...ssoDetails, isSsoLoading: false });
      setErrorMsg({
        ...errorMsg,
        outlookError: error?.message || 'Something went wrong ',
      });
      setTimeout(() => {
        setErrorMsg({
          ...errorMsg,
          outlookError: '',
        });
      }, 5000);
    }
  };

  const saveAuthTokens = async (data) => {
    setSaveAuthLoading(true);
    try {
      await saveAuthToken(data);
      setSaveAuthLoading(false);
      loadSSODetails();
    } catch (error) {
      console.log('saveAuthToken', error);
      setSaveAuthLoading(false);
      setErrorMsg({
        ...errorMsg,
        outlookError: error?.message || 'Something went wrong ',
      });
      loadSSODetails();
      setTimeout(() => {
        setErrorMsg({
          ...errorMsg,
          outlookError: '',
        });
      }, 5000);
    }
  };
  const forwardGoogleSSO = async () => {
    setGoogleSsoDetails({ ...googleSsoDetails, isGoogleSsoLoading: true });
    try {
      const result = await googleSSO();
      setGoogleSsoDetails(result);
      setGoogleSsoDetails({ ...result, isGoogleSsoLoading: false });
    } catch (error) {
      console.log('googleSSO', error);
      setGoogleSsoDetails({ ...googleSsoDetails, isGoogleSsoLoading: false });
      setErrorMsg({
        ...errorMsg,
        googleEror: error?.message || 'Something went wrong ',
      });
      setTimeout(() => {
        setErrorMsg({
          ...errorMsg,
          googleEror: '',
        });
      }, 5000);
    }
  };
  const saveGoogleAuthTokens = async (data) => {
    setGoogleAuthLoading(true);
    try {
      await saveGoogleAuthToken(data);
      setGoogleAuthLoading(false);
      forwardGoogleSSO();
    } catch (error) {
      console.log('saveAuthToken', error);
      setGoogleAuthLoading(false);
      setErrorMsg({
        ...errorMsg,
        googleEror: error?.message || 'Something went wrong ',
      });
      forwardGoogleSSO();
      setTimeout(() => {
        setErrorMsg({
          ...errorMsg,
          googleEror: '',
        });
      }, 5000);
    }
  };

  const loadDefaultMeetingSettings = async () => {
    try {
      const result = await getDefaultMeetingSettings();
      if (Object.keys(result).length === 0) {
        setRequireMeetingSettings({
          message: 'You need to first update the default meeting settings!!!',
          showMsg: false,
        });
      } else {
        setRequireMeetingSettings({
          message: '',
          showMsg: false,
        });
      }
    } catch (error) {
      setRequireMeetingSettings({
        message: 'You need to first update the default meeting settings!!!',
        showMsg: false,
      });
      console.log('load default meeting settings', error);
    }
  };
  const loadAvailableSlot = async () => {
    const todaysWeek = new Date().getDay();
    const fromDate = format(subDays(new Date(), todaysWeek), 'yyyy-MM-dd');
    const toDate = format(addDays(new Date(), 13 - todaysWeek), 'yyyy-MM-dd');
    try {
      const result = await getAvailableSlot({
        collegeId: schoolId,
        counselorId: id,
        fromDate,
        toDate,
      });

      if (result.length === 0) {
        setNoSlotsAvailable(true);
      } else {
        setNoSlotsAvailable(false);
      }
    } catch (error) {
      console.log('get available slots', error);
    }
  };
  useEffect(() => {
    const { code, scope } = queryString.parse(location.search);
    if (code) {
      if (!scope) {
        saveAuthTokens({ code });
        forwardGoogleSSO();
      } else {
        saveGoogleAuthTokens(code);
        loadSSODetails();
      }
    } else {
      loadSSODetails();
      forwardGoogleSSO();
    }
    loadDefaultMeetingSettings();
    loadAvailableSlot();
  }, []);
  return (
    <>
      <div className="main dashBoard SettingsPage">
        <div className="headertitle">
          <h2>Settings</h2>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '33px' }}
        >
          {showMsg && <p className="err">{message}</p>}
        </div>

        <div className="authBoxesWrapper">
          <div className="autoBoxItemWrapper">
            <div
              className="autoBoxItem"
              onClick={() => {
                message &&
                  setRequireMeetingSettings({
                    ...requireMeetingSettings,
                    showMsg: true,
                  });
              }}
            >
              <a href={!message ? gUrl : false}>
                <span>
                  <GoogleLogoAuth />
                </span>
                <h4>
                  {!isGoogleSsoLoading && !googleAuthLoading
                    ? isPreviouslyAuthenticated === 0
                      ? 'Authenticate via Google'
                      : isPreviouslyAuthenticated === 1
                      ? 'Reauthenticate via Google'
                      : 'Authenticate via Google'
                    : googleEror || 'Loading...'}
                </h4>
              </a>
            </div>

            <div
              className="autoBoxItem"
              onClick={() => {
                message &&
                  setRequireMeetingSettings({
                    ...requireMeetingSettings,
                    showMsg: true,
                  });
              }}
            >
              <a href={!message ? url : false}>
                <span>
                  <MicrosoftLogoAuth />
                </span>
                <h4>
                  {!isSsoLoading && !saveAuthLoading
                    ? isPreviouslyAthenticated === 0
                      ? 'Authenticate via Microsoft Outlook'
                      : isPreviouslyAthenticated === 1
                      ? 'Reauthenticate via Microsoft Outlook'
                      : 'Reauthenticate via Microsoft Outlook'
                    : outlookError || 'Loading...'}
                </h4>
              </a>
            </div>
            <div
              className="autoBoxItem"
              onClick={() => {
                setRequireMeetingSettings({
                  ...requireMeetingSettings,
                  showMsg: false,
                });
                showHideDefaultMeetingModal(true);
              }}
            >
              <span>
                <UpdateMeetingSettingsAuth />
              </span>
              <h4>Update default meeting settings</h4>
            </div>

            {!noSlotsAvailable && isFirstTimeLogin === 1 && (
              <div
                className="autoBoxItem"
                onClick={() => {
                  if (message) {
                    setRequireMeetingSettings({
                      ...requireMeetingSettings,
                      showMsg: true,
                    });
                    return;
                  }
                  setCalendarProps({
                    viewOnly: true,
                    editable: false,
                    navigationAllowed: true,
                    firstTime: false,
                    skipWeeks: false,
                  });
                  showHideCalendarModal(true);
                }}
              >
                <span>
                  <UpdateCalendarAvailabilityAuth />
                </span>
                <h4>
                  View calendar availability <span>live</span>
                </h4>
              </div>
            )}
            <div
              className="autoBoxItem"
              onClick={() => {
                if (message) {
                  setRequireMeetingSettings({
                    ...requireMeetingSettings,
                    showMsg: true,
                  });
                  return;
                }
                setCalendarProps({
                  viewOnly: false,
                  editable: true,
                  navigationAllowed: true,
                  firstTime: false,
                  skipWeeks: !noSlotsAvailable,
                });
                showHideCalendarModal(true);
              }}
            >
              <span>
                <UpdateCalendarAvailabilityAuth />
              </span>
              <h4>Set upcoming calendar availability</h4>
            </div>
          </div>
        </div>
      </div>
      {showDefaultMeetingModal && (
        <DefaultMeetingSettingsModal
          show={showDefaultMeetingModal}
          showHideModal={showHideDefaultMeetingModal}
        />
      )}
      {showCalendarModal && (
        <CalendarModal
          show={showCalendarModal}
          showHideModal={showHideCalendarModal}
          editable={editable}
          navigationAllowed={navigationAllowed}
          firstTime={firstTime}
          viewOnly={viewOnly}
          skipWeeks={skipWeeks}
        />
      )}
    </>
  );
}

export default DashBoard;
