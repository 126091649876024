import React from 'react';

function PlusIcon() {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7323 11.7305H16.4768V1.96556C16.4768 1.61212 16.3153 1.28469 16.0477 1.0515C15.7817 0.81964 15.43 0.696021 15.0718 0.696021C14.7135 0.696021 14.3618 0.81964 14.0958 1.0515C13.8282 1.28469 13.6667 1.61212 13.6667 1.96556V11.7305H2.41121C2.05292 11.7305 1.70124 11.8541 1.43521 12.086C1.16957 12.3175 1.00847 12.6419 1.00618 12.9925C0.999814 13.1673 1.03603 13.3399 1.11057 13.4983C1.18607 13.6588 1.29825 13.7992 1.43617 13.9122C1.57399 14.0251 1.73507 14.1087 1.90754 14.1598C2.07481 14.2093 2.25097 14.2277 2.42539 14.2144H13.6667V24.0345C13.6667 24.388 13.8282 24.7154 14.0958 24.9486C14.3618 25.1805 14.7135 25.3041 15.0718 25.3041C15.43 25.3041 15.7817 25.1805 16.0477 24.9486C16.3153 24.7154 16.4768 24.388 16.4768 24.0345V14.2696H27.7323C28.0906 14.2696 28.4423 14.146 28.7083 13.9141C28.9759 13.6809 29.1374 13.3535 29.1374 13C29.1374 12.6466 28.9759 12.3192 28.7083 12.086C28.4423 11.8541 28.0906 11.7305 27.7323 11.7305Z"
        fill="#A2A2A2"
        stroke="#A2A2A2"
        strokeWidth="0.7"
      />
    </svg>
  );
}

export default PlusIcon;
