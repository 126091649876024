import React from 'react';
function HomeIconPvtNav({ color, tooltip, place }) {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      data-tip={tooltip}
      data-place={place}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5878 0.428774C10.2938 0.153422 9.90456 0 9.5 0C9.09544 0 8.70623 0.153422 8.41225 0.428774L0.742585 7.60997C0.507747 7.83013 0.320755 8.09548 0.193066 8.38975C0.0653775 8.68403 -0.000319156 9.00103 1.16573e-06 9.32134V18.0586C0.000420909 18.6824 0.250829 19.2805 0.696182 19.7214C1.14154 20.1623 1.74539 20.41 2.375 20.41H4.75C5.37989 20.41 5.98398 20.1621 6.42938 19.7208C6.87478 19.2796 7.125 18.6811 7.125 18.0571V14.1355C7.125 13.9275 7.20841 13.728 7.35687 13.5809C7.50534 13.4338 7.7067 13.3512 7.91667 13.3512H11.0833C11.2933 13.3512 11.4947 13.4338 11.6431 13.5809C11.7916 13.728 11.875 13.9275 11.875 14.1355V18.0571C11.875 18.6811 12.1252 19.2796 12.5706 19.7208C13.016 20.1621 13.6201 20.41 14.25 20.41H16.625C17.2549 20.41 17.859 20.1621 18.3044 19.7208C18.7498 19.2796 19 18.6811 19 18.0571V9.31978C18.9999 8.99961 18.9339 8.68284 18.8059 8.38884C18.6779 8.09485 18.4908 7.82981 18.2558 7.60997L10.5878 0.425636V0.428774Z"
        fill={color}
      />
    </svg>
  );
}
HomeIconPvtNav.defaultProps = {
  color: '#8E8E8E',
};
export default HomeIconPvtNav;
