import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer({ url }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <>
      <div>
        <Document
          className="pdf-viewer"
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading File"
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    </>
  );
}

export default PdfViewer;
