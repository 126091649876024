import React from 'react';

function UpdateCalendarAvailabilityAuth() {
  return (
    <svg
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.8707 3.75195H30.2096V0.539062H26.879V3.75195H13.5567V0.539062H10.2261V3.75195H3.56495C2.6819 3.7528 1.83527 4.09158 1.21085 4.69393C0.586439 5.29628 0.235257 6.11299 0.234375 6.96484V39.0938C0.235257 39.9456 0.586439 40.7623 1.21085 41.3647C1.83527 41.967 2.6819 42.3058 3.56495 42.3066H36.8707C37.7538 42.3058 38.6004 41.967 39.2248 41.3647C39.8492 40.7623 40.2004 39.9456 40.2013 39.0938V6.96484C40.2004 6.11299 39.8492 5.29628 39.2248 4.69393C38.6004 4.09158 37.7538 3.7528 36.8707 3.75195ZM3.56495 6.96484H10.2261V10.1777H13.5567V6.96484H26.879V10.1777H30.2096V6.96484H36.8707V13.3906H3.56495V6.96484ZM3.56495 16.6035H11.8914V26.2422H3.56495V16.6035ZM25.2137 39.0938H15.222V29.4551H25.2137V39.0938ZM25.2137 26.2422H15.222V16.6035H25.2137V26.2422ZM28.5443 39.0938V29.4551H36.8707L36.8724 39.0938H28.5443Z"
        fill="#999999"
      />
    </svg>
  );
}

export default UpdateCalendarAvailabilityAuth;
