import React from 'react';

function LoginImageComp() {
  return (
    <div className="pico-leftImage">
      <img src={'images/pico-loginImage.png'} alt="pico" />
    </div>
  );
}

export default LoginImageComp;
