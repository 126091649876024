import React from 'react';

function EditIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0374 3.30144C9.84212 3.10618 9.52554 3.10618 9.33027 3.30144L0.146446 12.4853C0.0526781 12.579 0 12.7062 0 12.8388V15.5C0 15.7761 0.223858 16 0.5 16H3.16118C3.29379 16 3.42097 15.9473 3.51473 15.8536L12.6986 6.66972C12.8938 6.47446 12.8938 6.15788 12.6986 5.96262L10.0374 3.30144ZM15.3021 0.697867C14.3716 -0.232622 12.8643 -0.232622 11.9338 0.697867L11.3005 1.33122C11.1052 1.52648 11.1052 1.84307 11.3005 2.03833L13.9617 4.69951C14.1569 4.89477 14.4735 4.89477 14.6688 4.69951L15.3021 4.06616C16.2318 3.13651 16.2318 1.62836 15.3021 0.697867Z"
        fill={color}
      />
    </svg>
  );
}

EditIcon.defaultProps = {
  color: '#1A487D',
};

export default EditIcon;
