import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Passeye from './svg/passeye';
import PasseyeCrossed from './svg/passeyeCrossed';

function CreatePasswordComp({
  title1,
  title2,
  emailVerificationStatus: { email, status },
  createCounsellorCredential,
  errorResponse,
  isLoading,
}) {
  const [pswVisible1, setPswVisible1] = useState(false);
  const [pswVisible2, setPswVisible2] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const password = useRef({});
  password.current = watch('password', '');
  const submit = handleSubmit((data) => {
    createCounsellorCredential({ password: data.password, email });
  });
  return (
    <div className="pico-formwrapper">
      <div className="formPico">
        {status && <h3>{title1}</h3>}
        {status && title2 && <h5>{title2}</h5>}

        <form action="#" className="signin-form">
          {status ? (
            <>
              <div className="form-group mb-3">
                <label className="label" for="password">
                  Password
                </label>
                <div className="inputwithappendicon">
                  <div className="inputfieldwrap">
                    <input
                      type={pswVisible1 ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Password"
                      {...register('password', {
                        required: 'Password Required',
                        pattern: {
                          value:
                            /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()])[\w!@#$%^&*()]{8,}$/,
                          message:
                            'Password should have minimum 8 characters,should include at least one small case letter,one capital letter,one number and one special character.',
                        },
                      })}
                    />
                  </div>
                  <div
                    className="eyeIconSpan"
                    onClick={() => {
                      setPswVisible1(!pswVisible1);
                    }}
                  >
                    {!pswVisible1 ? <PasseyeCrossed /> : <Passeye />}
                  </div>
                </div>
                {errors.password && (
                  <span className="err">{errors.password.message}</span>
                )}
              </div>
              <div className="form-group mb-3">
                <label className="label" for="Confirm password">
                  Confirm password
                </label>
                <div className="inputwithappendicon">
                  <div className="inputfieldwrap">
                    <input
                      type={pswVisible2 ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Confirm Password"
                      {...register('confirmpassword', {
                        validate: (value) =>
                          value === password.current ||
                          'The passwords do not match',
                      })}
                    />
                  </div>
                  <div
                    className="eyeIconSpan"
                    onClick={() => {
                      setPswVisible2(!pswVisible2);
                    }}
                  >
                    {!pswVisible2 ? <PasseyeCrossed /> : <Passeye />}
                  </div>
                </div>
                {(errors.confirmpassword || errorResponse) && (
                  <span className="err">
                    {errors.confirmpassword?.message || errorResponse}
                  </span>
                )}
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="form-control btn btn-primary submit px-3"
                  onClick={() => {
                    submit();
                  }}
                  disabled={isLoading}
                >
                  {!isLoading ? 'Create New Password' : 'Creating...'}
                </button>
              </div>
              {/* <div className="form-group d-md-flex newtosignup">
            <div className="w-50 text-center">
              New to pico?{' '}
              <a href="#" className="forgot-pass">
                Sign up
              </a>
            </div>
          </div> */}{' '}
            </>
          ) : (
            <h1>Link Expired</h1>
          )}
        </form>
      </div>
    </div>
  );
}

export default CreatePasswordComp;
