import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';

import { useForm } from 'react-hook-form';
import CloseIcon from '../../components/svg/closeIcon';
import ReactInputMask from 'react-input-mask';
import {
  counsellorType,
  createDefaultMeetingSettings,
  getDefaultMeetingSettings,
  updateDefaultMeetingSettings,
} from '../../modals';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '428px',
    padding: '36px',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function DefaultMeetingSettingsModal({
  show,
  showHideModal,
  firstLoading = false,
  saveConfirmation,
}) {
  const urlRegex = new RegExp('^(Http|Https|http|https):(//)', 'i');
  const [counsellorTypes, setCounsellorTypes] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onSubmit',
  });
  let phoneNumbererror = false;
  const phonenumberindex = [1, 2, 3, 6, 7, 8, 10, 11, 12, 13];
  const validatePhoneNUmber = (number = '') => {
    let ph;
    if (number) ph = number;
    if (!number) ph = phoneNumber;
    phoneNumbererror = false;
    if (!ph) {
      phoneNumbererror = true;
      setPhoneError(true);
      return;
    }
    ph.split('').map((item, index) => {
      if (phonenumberindex.includes(index))
        if (isNaN(parseInt(item))) phoneNumbererror = true;
    });
    setPhoneError(phoneNumbererror);
  };
  const submit = handleSubmit((data) => {
    if (phoneNumbererror) return;
    setPhoneError(false);
    saveDefaultMeetingSettings(data);
  });

  const loadDefaultMeetingSettings = async () => {
    setIsDataLoading(true);
    try {
      const result = await getDefaultMeetingSettings();
      const { counselorType, meetingLink, officeLocation, phoneNumber } =
        result;
      (meetingLink || officeLocation || phoneNumber || counselorType) &&
        setEditMode(true);
      setValue('counselorType', counselorType);
      setValue('meetingLink', meetingLink);
      setValue('officeLocation', officeLocation);
      setPhoneNumber(phoneNumber);
      setIsDataLoading(false);
    } catch (error) {
      setIsDataLoading(false);
      console.log('load default meeting settings', error);
      if (!firstLoading) {
        setApiResponse(error?.message || 'Data Loading Failed!!!');
        setTimeout(() => {
          setApiResponse('');
        }, 5000);
      }
    }
  };

  const saveDefaultMeetingSettings = async (data) => {
    try {
      data.phoneNumber = phoneNumber;
      if (editMode) {
        await updateDefaultMeetingSettings(data);
      } else {
        await createDefaultMeetingSettings(data);
      }
      setApiResponse('Saved Successfully!!!');
      firstLoading && saveConfirmation();
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    } catch (error) {
      console.log('create default meeting settings', error.message);
      setApiResponse(error?.message || 'Failed!!!');
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    }
  };

  const loadCounsellorType = async () => {
    try {
      const result = await counsellorType();
      setCounsellorTypes(result);
      loadDefaultMeetingSettings();
    } catch (error) {
      console.log('counsellortype', error);
      loadDefaultMeetingSettings();
    }
  };
  useEffect(() => {
    loadCounsellorType();
  }, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popheader SchedulecalanderModal">
          <h3>Default meeting settings</h3>

          <span className="popheaderspan">
            <button
              className="transpBtn"
              onClick={() => {
                showHideModal(false);
              }}
            >
              {<CloseIcon />}
            </button>
          </span>
        </div>
        <div class="content">
          {isDataLoading ? (
            <div style={{ margin: 'auto ,auto' }}>Loading...</div>
          ) : (
            <div className="SchedulecalanderModal">
              <ul className="studentInfo-labelInput">
                <li>
                  <label>Office location</label>
                  <input
                    type="text"
                    className="nborder"
                    placeholder="Enter address"
                    {...register('officeLocation', { required: 'Required' })}
                  />
                  <span className="err">{errors.officeLocation?.message}</span>
                </li>

                <li>
                  <label>Web Conference</label>
                  <input
                    type="text"
                    className="nborder"
                    placeholder="Enter URL"
                    {...register('meetingLink', {
                      required: 'Required',
                      pattern: {
                        value: urlRegex,
                        message: 'Url should start with http:// or  https:// ',
                      },
                    })}
                  />
                  <span className="err">{errors.meetingLink?.message}</span>
                </li>

                <li>
                  <label>Phone number</label>
                  <ReactInputMask
                    mask="(999)-999-9999"
                    value={phoneNumber}
                    onChange={({ target: { value } }) => {
                      setPhoneNumber(value);
                      phoneError && validatePhoneNUmber(value);
                    }}
                  >
                    {() => (
                      <input
                        type="text"
                        className="nborder"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        name="phoneNumber"
                      />
                    )}
                  </ReactInputMask>
                  <span className="err">
                    {phoneError && 'Required a valid number'}
                  </span>
                </li>
                <li>
                  <label>Counselor Type</label>
                  <select
                    className="nborder"
                    {...register('counselorType', { required: 'Required' })}
                  >
                    <option value="">select</option>
                    {counsellorTypes.map(({ id, name }) => (
                      <option value={id}>{name}</option>
                    ))}
                  </select>
                  <span className="err">{errors.counselorType?.message}</span>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="popfooter">
          <span className="err">{apiResponse}</span>
          <button
            onClick={() => {
              validatePhoneNUmber();
              submit();
            }}
            className="btn blueroundedbtn"
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DefaultMeetingSettingsModal;
