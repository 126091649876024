import React from 'react';
import ResetPasswordImage from './svg/resetPasswordImage';
import '../css/userLogin.css';

function ForgotPasswordComp({ email }) {
  return (
    <div className="formPico resetpassword">
      <h3>Forgot Password</h3>
      <span className="resetImage">
        <ResetPasswordImage />
      </span>
      <p>{`Password reset instructions have been sent to ${email}`}</p>
    </div>
  );
}

export default ForgotPasswordComp;
