import { Request, requestNoToken } from '../utils';

export const login = async (data) => {
  const result = await requestNoToken.post('login', data);
  return result;
};

export const tokenRefresh = async () => {
  const result = await Request.get('users/refresh-token');
  return result;
};
