import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../home/home';
import StudentList from '../student/studentList';
import Navbar from '../navbar/navbar';
import CreatePassword from '../userManagement/createPassword';
import UserLogin from '../userManagement/userLogin';
import PrivateRoute from './privateRoute';
import NavbarPrivate from '../navbar/navbarPrivate';
import StudentDocument from '../student/studentDocument';
import DashBoard from '../dashBoard/dashBoard';
import CalenderWide from '../calendar/calendarWide';
import Settings from '../settings/settings';
import EditDefaultMeetingSettings from '../dashBoard/editDefaultMeetingSettings';

const Routes = ({ updateLoginStatus, loggedIn, sessionTimer }) => {
  return (
    <>
      {localStorage.getItem('logintoken') || loggedIn ? (
        <NavbarPrivate updateLoginStatus={updateLoginStatus} />
      ) : (
        <Navbar />
      )}

      <Switch>
        <Route path="/login">
          <UserLogin
            updateLoginStatus={updateLoginStatus}
            sessionTimer={sessionTimer}
          />
        </Route>
        <Route path="/createpassword">
          <CreatePassword />
        </Route>
        {/* <PrivateRoute path="/home">
          <Home />
        </PrivateRoute> */}
        <PrivateRoute path="/studentlist">
          <StudentList />
        </PrivateRoute>
        <PrivateRoute path="/studentdocument">
          <StudentDocument />
        </PrivateRoute>
        <PrivateRoute path="/home">
          <DashBoard />
        </PrivateRoute>
        <PrivateRoute path="/calendar">
          <CalenderWide />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <Settings />
        </PrivateRoute>
        <PrivateRoute path="/editdefaultmeetingsettings">
          <EditDefaultMeetingSettings />
        </PrivateRoute>
      </Switch>
    </>
  );
};
export default Routes;
