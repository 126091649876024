import React from 'react';

function Passeye() {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28115 8C8.28115 8.69619 8.55771 9.36387 9.05 9.85616C9.54228 10.3484 10.21 10.625 10.9062 10.625C11.6024 10.625 12.27 10.3484 12.7623 9.85616C13.2546 9.36387 13.5312 8.69619 13.5312 8C13.5312 7.30381 13.2546 6.63613 12.7623 6.14384C12.27 5.65156 11.6024 5.375 10.9062 5.375C10.21 5.375 9.54228 5.65156 9.05 6.14384C8.55771 6.63613 8.28115 7.30381 8.28115 8ZM21.0827 7.39531C18.8609 2.71484 15.5023 0.359375 10.9999 0.359375C6.49521 0.359375 3.13896 2.71484 0.917079 7.39766C0.827959 7.58637 0.781738 7.79247 0.781738 8.00117C0.781738 8.20987 0.827959 8.41598 0.917079 8.60469C3.13896 13.2852 6.49756 15.6406 10.9999 15.6406C15.5046 15.6406 18.8609 13.2852 21.0827 8.60234C21.2632 8.22266 21.2632 7.78203 21.0827 7.39531ZM10.9062 12.125C8.62803 12.125 6.78115 10.2781 6.78115 8C6.78115 5.72188 8.62803 3.875 10.9062 3.875C13.1843 3.875 15.0312 5.72188 15.0312 8C15.0312 10.2781 13.1843 12.125 10.9062 12.125Z"
        fill="#CBCBCB"
      />
    </svg>
  );
}

export default Passeye;
