import { Request, requestNoToken } from '../utils';

export const getClassesTaken = async (data) => {
  const result = await Request.get('my-academics/taken-classes', data);
  return result;
};
export const getClassesLeft = async (data) => {
  const result = await Request.get('my-academics/left-classes', data);
  return result;
};
export const getGoalProgress = async (data) => {
  const result = Request.get('my-academics/academic-goals-progress', data);
  return result;
};
