import React from 'react';

function CloseIconBig() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.61 16.6892C18.8613 16.9446 19.0014 17.289 19 17.6473C18.9985 18.0056 18.8555 18.3488 18.6022 18.6022C18.3488 18.8555 18.0056 18.9985 17.6473 19C17.289 19.0014 16.9446 18.8613 16.6892 18.61L9.5 11.4209L2.31084 18.61C2.05542 18.8613 1.71104 19.0014 1.35273 19C0.994416 18.9985 0.651193 18.8555 0.397825 18.6022C0.144457 18.3488 0.0014703 18.0056 1.12757e-05 17.6473C-0.00144774 17.289 0.138739 16.9446 0.390035 16.6892L7.57908 9.5L0.390035 2.31084C0.138739 2.05542 -0.00144774 1.71104 1.12757e-05 1.35273C0.0014703 0.994416 0.144457 0.651193 0.397825 0.397825C0.651193 0.144457 0.994416 0.0014703 1.35273 1.12757e-05C1.71104 -0.00144774 2.05542 0.138739 2.31084 0.390035L9.5 7.57908L16.6892 0.390035C16.9446 0.138739 17.289 -0.00144774 17.6473 1.12757e-05C18.0056 0.0014703 18.3488 0.144457 18.6022 0.397825C18.8555 0.651193 18.9985 0.994416 19 1.35273C19.0014 1.71104 18.8613 2.05542 18.61 2.31084L11.4209 9.5L18.61 16.6892Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

export default CloseIconBig;
