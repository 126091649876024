import React from 'react';
import { useHistory } from 'react-router';
// import InspirameLogo from '../components/svg/inspirameLogo';
import TecoLogoandText from '../components/svg/tecoLogoandText';

const Navbar = () => {
  const history = useHistory();
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-fixed-top">
        <div className="container">
          {/* begin logo  */}
          <a
            className="navbar-brand"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              history.push('/');
            }}
          >
            {/* <img src="images/pico-logo.png" /> */}
            {/* <InspirameLogo /> */}
            <TecoLogoandText
              onClick={() => {
                history.push('/home');
              }}
            />
          </a>
          {/* end logo  */}

          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"><i className="bi bi-list"></i></span>
      </button>  */}

          <div className="collapse navbar-collapse" id="navbarScroll">
            {/* begin navbar-nav */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#feature">
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#ourmission">
                  Our mission
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item selected">
                <a
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('login');
                  }}
                >
                  <button type="button" className="btn btn-primary">
                    Login
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
