import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ClockIcon from '../../components/svg/clockIcon';
import CloseIcon from '../../components/svg/closeIcon';
import GoupIconYellow from '../../components/svg/goupIconYellow';
import TextAreaIcon from '../../components/svg/textAreaIcon';
import NotesIcon from '../../components/svg/notesIcon';
import WebCamIcon from '../../components/svg/webCamIcon';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import PhoneReceiverIcon from '../../components/svg/phoneReceiverIcon';
import InpersonIcon from '../../components/svg/inpersonIcon';
import MeetingTypePhone from '../../components/svg/meetingtypePhone';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    padding: '30px 20px 30px 20px',
    borderRadius: '25px',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function MeetingInfoModal({
  show,
  showHideModal,
  meetingInfo,
  fromTitle = 'Home',
  fromLink = '/home',
}) {
  const history = useHistory();
  const {
    startAndEndTime,
    meetingDate,
    studentDetail,
    meetingLink,
    meetingDetails,
    studentId,
    meetingReasonTypeDesc,
    meetingTypeDesc,
    meetingType,
    counselorPhoneNumber,
  } = meetingInfo;
  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popheader SchedulecalanderModal">
          <h2>{studentDetail[0]?.fullName}</h2>
          <span className="popheaderspan">
            <button
              className="transpBtn"
              onClick={() => {
                showHideModal(false);
              }}
            >
              {<CloseIcon />}
            </button>
          </span>
        </div>
        <div class="content">
          <div className="SchedulecalanderModal">
            <ul className="studentInfo">
              <li>
                <i>{<ClockIcon />}</i>
                <div className="medateandTime">
                  <span className="meetingdate">
                    {format(new Date(meetingDate), 'MMMM dd, yyyy')}
                  </span>
                  <span className="meetingTime">{startAndEndTime}</span>
                </div>
              </li>
              <li>
                <i>
                  <NotesIcon />
                </i>
                <div>
                  <p>Meeting</p>
                </div>
              </li>
              <li>
                <i>
                  <GoupIconYellow />
                </i>
                <div
                  className="userName"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push({
                      pathname: '/studentlist',
                      studentId: studentDetail[0]?.studentId,
                    });
                    localStorage.setItem('backlink_title_pico', fromTitle);
                    localStorage.setItem('backLink_pico', fromLink);
                  }}
                >
                  {studentDetail[0]?.fullName}
                  <span>({studentDetail[0]?.studentId})</span>
                </div>
              </li>

              <li>
                <i>
                  <NotesIcon />
                </i>
                <div>
                  <p>{meetingReasonTypeDesc}</p>
                </div>
              </li>

              <li>
                <i>
                  {meetingType === 2 && <MeetingTypePhone />}
                  {meetingType === 1 && <WebCamIcon />}
                  {meetingType === 3 && <InpersonIcon />}
                </i>
                <div className="meetingDetails">
                  <div>
                    <p>{meetingTypeDesc}</p>
                  </div>
                  {meetingType === 1 && (
                    <div>
                      <button>
                        <a href={meetingLink} target="__balnk">
                          Join on meet
                        </a>
                      </button>
                    </div>
                  )}
                  <div className="meetLink">
                    {meetingType === 1 && (meetingLink || '--')}
                    {meetingType === 2 && (counselorPhoneNumber || '--')}
                    {meetingType === 3 && ''}
                  </div>
                </div>
              </li>

              <li className="meeting-info-notes">
                <i>
                  <TextAreaIcon />
                </i>
                {/* <textarea placeholder="Add description"> */}
                <p>{meetingDetails}</p>
                {/* </textarea> */}
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MeetingInfoModal;
