import React from 'react';

function PasseyeCrossed() {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1262 11.802C10.8225 11.802 11.4903 11.5254 11.9827 11.033C12.4751 10.5406 12.7517 9.87284 12.7517 9.17652C12.7517 9.09963 12.7482 9.02344 12.7416 8.9482L9.8979 11.7919C9.97314 11.7985 10.0491 11.802 10.1262 11.802ZM18.8165 1.05538L17.8151 0.0548837C17.7799 0.0197409 17.7322 0 17.6825 0C17.6328 0 17.5851 0.0197409 17.55 0.0548837L14.9873 2.61823C13.5736 1.89576 11.9845 1.53452 10.22 1.53452C5.71449 1.53452 2.35295 3.88104 0.135365 8.57407C0.0462286 8.76281 0 8.96896 0 9.17769C0 9.38642 0.0462286 9.59257 0.135365 9.78131C1.02146 11.6477 2.08845 13.1437 3.33633 14.2692L0.856431 16.7482C0.821289 16.7834 0.801548 16.831 0.801548 16.8808C0.801548 16.9305 0.821289 16.9781 0.856431 17.0133L1.85716 18.014C1.89232 18.0492 1.94 18.0689 1.98972 18.0689C2.03944 18.0689 2.08712 18.0492 2.12228 18.014L18.8165 1.32074C18.834 1.30332 18.8478 1.28264 18.8572 1.25987C18.8667 1.2371 18.8715 1.2127 18.8715 1.18806C18.8715 1.16341 18.8667 1.13901 18.8572 1.11624C18.8478 1.09348 18.834 1.07279 18.8165 1.05538ZM6.00048 9.17652C6.00041 8.4639 6.18493 7.76342 6.53605 7.14331C6.88716 6.52321 7.39291 6.00461 8.00402 5.63806C8.61514 5.27151 9.31077 5.06949 10.0232 5.05169C10.7356 5.03389 11.4404 5.20091 12.0691 5.53649L10.9293 6.67623C10.4697 6.52905 9.97838 6.51132 9.50933 6.625C9.04027 6.73867 8.61159 6.97934 8.27032 7.32062C7.92904 7.66189 7.68837 8.09057 7.5747 8.55963C7.46102 9.02868 7.47875 9.51999 7.62593 9.97963L6.48619 11.1194C6.16635 10.5218 5.99948 9.8543 6.00048 9.17652V9.17652Z"
        fill="#CBCBCB"
      />
      <path
        d="M20.3044 8.57171C19.4793 6.8339 18.4971 5.41685 17.358 4.32056L13.9791 7.69968C14.2639 8.44422 14.3271 9.25531 14.1609 10.035C13.9947 10.8146 13.6063 11.5294 13.0426 12.0931C12.479 12.6568 11.7641 13.0452 10.9845 13.2114C10.2048 13.3776 9.39373 13.3144 8.64919 13.0296L5.7832 15.8956C7.11282 16.5109 8.59168 16.8185 10.2198 16.8185C14.7253 16.8185 18.0868 14.472 20.3044 9.77896C20.3936 9.59022 20.4398 9.38407 20.4398 9.17533C20.4398 8.9666 20.3936 8.76045 20.3044 8.57171V8.57171Z"
        fill="#CBCBCB"
      />
    </svg>
  );
}

export default PasseyeCrossed;
