import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import CreatePasswordComp from '../components/createPasswordComp';
import LoginImageComp from '../components/image/loginImageComp';
import { useHistory, useLocation } from 'react-router';
import { counsellorEmailVerification, createCounsellorCred } from '../modals';

const CreatePassword = () => {
  const location = useLocation();
  const history = useHistory();

  const [emailVerificationStatus, setEmailVerificationStatus] = useState({
    status: true,
  });
  const [errorResponse, setErrorResponse] = useState();
  const [params, setParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const verifyEmail = async (data) => {
    try {
      const result = await counsellorEmailVerification(data);
      console.log(result);
      result['Status '] === ' success' &&
        setEmailVerificationStatus({ email: data.email, status: true });
    } catch (error) {
      setEmailVerificationStatus({ email: data.email, status: false });
      console.log('email verification', error);
    }
  };
  const createCounsellorCredential = async (data) => {
    setIsLoading(true);
    try {
      await createCounsellorCred(data);
      history.push({
        pathname: '/login',
        title1:
          params.resetPassword === 'true' ? 'New password set' : 'Password set',
        title2: 'Log in with your new password',
      });
      setErrorResponse('');
    } catch (error) {
      setIsLoading(false);
      setErrorResponse(error.message);
      console.log('counsellor credential create', error);
      setTimeout(() => {
        setErrorResponse('');
      }, 10000);
    }
  };
  useEffect(() => {
    const paramss = queryString.parse(location.search);
    if (!paramss.email) {
      history.push('home');
      return;
    }
    setParams(paramss);
    paramss.email &&
      verifyEmail({ email: paramss.email, emailToken: paramss.emailToken });
  }, []);
  return (
    <>
      <div className="pico-contentWrapper">
        <div className="pico-container flexcontainer">
          <LoginImageComp />
          <div className="pico-formwrapper">
            <CreatePasswordComp
              title1={
                params.resetPassword === 'true'
                  ? 'Create New Password'
                  : 'Create a password'
              }
              title2={params.resetPassword === 'true' ? '' : params.email}
              emailVerificationStatus={emailVerificationStatus}
              createCounsellorCredential={createCounsellorCredential}
              errorResponse={errorResponse}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>{' '}
    </>
  );
};
export default CreatePassword;
