import React, { useState } from 'react';
import StudentDocFullViewer from './modal/studentDocFullViewer';
import PdfViewer from './pdfViewer';
function StudentDocument({ studentDocuments, docIsLoading }) {
  const imgExtension = ['jpg', 'jpeg', 'png', 'gif'];
  const docExtension = ['docx', 'doc', 'xlsx', 'xlsm', 'xls'];
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileType, setSelectedFileType] = useState('');
  const [showDocFullModal, setShowDocFullModal] = useState(false);
  const showHideDocFullModal = (bool) => {
    setShowDocFullModal(bool);
  };
  return (
    <>
      <div className="main studentDocument">
        <ul className="documentUl">
          {studentDocuments.length > 0 ? (
            studentDocuments.map(({ fileName, fileLocation, preSignedURL }) => {
              const exten = fileName.split('.');
              const extenlength = exten.length;
              const extension = exten[extenlength - 1];
              return (
                <li
                  style={{ cursor: 'pointer' }}
                  key={fileName}
                  onClick={() => {
                    if (!docExtension.includes(extension)) {
                      setSelectedFile({ fileName, preSignedURL });
                      imgExtension.includes(extension) &&
                        setSelectedFileType('image');
                      extension === 'pdf' && setSelectedFileType('pdf');
                      setShowDocFullModal(true);
                    }
                  }}
                >
                  <div className="docThmb">
                    {extension === 'pdf' && <PdfViewer url={preSignedURL} />}
                    {imgExtension.includes(extension) && (
                      <img src={preSignedURL} />
                    )}
                    {docExtension.includes(extension) && (
                      <a
                        className="viewfile"
                        href={preSignedURL}
                        target="_blank"
                      >
                        View File
                      </a>
                    )}
                  </div>
                  <h4>{fileName}</h4>
                </li>
              );
            })
          ) : (
            <h6>{docIsLoading ? 'Loading Documents...' : 'No Documents'}</h6>
          )}
        </ul>
      </div>
      <StudentDocFullViewer
        show={showDocFullModal}
        showHideModal={showHideDocFullModal}
        url={selectedFile}
        selectedFileType={selectedFileType}
      />
    </>
  );
}

export default StudentDocument;
