import React from 'react';

function PhoneReceiverGray() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33407 2.88317L3.35364 0.865505C3.4961 0.722417 3.66543 0.608886 3.8519 0.531434C4.03837 0.453982 4.23831 0.414136 4.44022 0.414185C4.85172 0.414185 5.23857 0.575371 5.5287 0.865505L7.70187 3.03867C7.84495 3.18113 7.95849 3.35046 8.03594 3.53693C8.11339 3.7234 8.15323 3.92334 8.15319 4.12525C8.15319 4.53675 7.992 4.9236 7.70187 5.21373L6.11276 6.80283C6.48474 7.62272 7.0019 8.36859 7.63929 9.00444C8.27508 9.64338 9.02088 10.1624 9.8409 10.5367L11.43 8.94756C11.5725 8.80447 11.7418 8.69094 11.9283 8.61348C12.1147 8.53603 12.3147 8.49619 12.5166 8.49623C12.9281 8.49623 13.3149 8.65742 13.6051 8.94756L15.7801 11.1188C15.9234 11.2615 16.037 11.4312 16.1145 11.618C16.1919 11.8048 16.2317 12.0051 16.2314 12.2073C16.2314 12.6188 16.0703 13.0056 15.7801 13.2958L13.7644 15.3116C13.3017 15.7761 12.6626 16.0397 12.0065 16.0397C11.868 16.0397 11.7353 16.0284 11.6045 16.0056C9.04824 15.5846 6.51288 14.225 4.46677 12.1808C2.42255 10.1384 1.0648 7.60497 0.638131 5.04117C0.509183 4.25799 0.768976 3.45206 1.33407 2.88317Z"
        fill="#797979"
      />
    </svg>
  );
}

export default PhoneReceiverGray;
