import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../components/svg/closeIcon';
import EditIcon from '../../components/svg/editIcon';
import { updateStudentDeatils } from '../../modals/counsellor';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '741px',
    borderRadius: '8px',
    padding: '20px 20px 100px 20px',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function StudentDetailModal({
  show,
  showHideModal,
  academicStandingList,
  updateStudentList,
  detailInfo: {
    academicStandings,
    goal,
    financialAidFlag,
    communityCollegeName,
    tags,
    studentTaskList,
    userFullName,
    phoneNumber,
    email,
    GETrack,
    enrollStatus,
    disabilityFlag,
    specialtyCounselingPrograms,
  },
}) {
  const [editMode, setEditMode] = useState(false);
  const [academicStndIndex, setAcademicStndIndex] = useState();
  const [errorUpdating, setErrorUpdating] = useState('');

  const updateStudentInfo = async () => {
    const { academicId, academicName } =
      academicStandingList[academicStndIndex];

    try {
      await updateStudentDeatils({
        academicStanding: academicId,
        email,
      });
      setEditMode(false);
      updateStudentList('academicStandings', {
        academicName,
        academicId,
      });
    } catch (error) {
      setErrorUpdating(error?.message || 'Something went wrong');
      console.log(error);
      setTimeout(() => {
        setErrorUpdating('');
      }, 5000);
    }
  };

  useEffect(() => {
    const index = academicStandingList.findIndex(
      (i) => parseInt(i.academicId) === parseInt(academicStandings.academicId)
    );
    console.log(index);
    setAcademicStndIndex(index);
  }, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          setEditMode(false);
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popheader">
          <h2>
            Student Details{' '}
            {!editMode && (
              <span
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <EditIcon />
              </span>
            )}
          </h2>

          <h4>{userFullName}</h4>
          <span className="popheaderspan">
            <button
              className="transpBtn"
              onClick={() => {
                setEditMode(false);
                showHideModal(false);
              }}
            >
              <CloseIcon />
            </button>
          </span>
        </div>
        <div class="content">
          <ul className="studentDetailss">
            <li>
              <div className="detailitem">
                <p className="superTitle">Goal</p>
                <h3>{goal || '--'}</h3>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Tags</p>
                <ul className="stutags">
                  {Object.keys(tags).map((key) => (
                    <li>
                      <span className="blue">{tags[key]}</span>{' '}
                    </li>
                  ))}

                  {Object.keys(tags).length === 0 && '--'}
                </ul>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Enrollment Status</p>
                <h3>{enrollStatus || '--'}</h3>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">GE Track</p>
                <h3>{GETrack || '--'}</h3>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Academic Standing</p>
                <ul className="stutags">
                  {!editMode && Object.keys(academicStandings).length > 0 && (
                    <li>
                      <span
                        className={academicStandings.academicName.toLowerCase()}
                      >
                        {academicStandings.academicName}
                      </span>
                    </li>
                  )}
                  {!editMode &&
                    Object.keys(academicStandings).length === 0 &&
                    '--'}
                  {editMode && (
                    <div className="popselect custom-select">
                      <select
                        value={academicStndIndex}
                        onChange={({ target: { value } }) => {
                          setAcademicStndIndex(value);
                        }}
                      >
                        {academicStandingList.map(({ academicName }, index) => (
                          <option value={index}>{academicName}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </ul>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Specialty Counseling Programs</p>
                <ul className="stutags">
                  {Object.keys(specialtyCounselingPrograms).map((key) => (
                    <li>
                      <span className="speccounprog">
                        {specialtyCounselingPrograms[key]}
                      </span>
                    </li>
                  ))}

                  {Object.keys(specialtyCounselingPrograms).length === 0 &&
                    '--:--'}
                </ul>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Community College</p>
                <h3>{communityCollegeName || '--'}</h3>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Disability Services</p>
                <h3>{disabilityFlag ? 'Yes' : 'No'}</h3>
              </div>
            </li>
          </ul>
        </div>

        <div className="popfooter">
          {editMode && errorUpdating && (
            <div class="errorMessage">{errorUpdating}</div>
          )}
          <div className="btnwrapper">
            {editMode && (
              <button
                className="btn transparentbtn"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                Cancel
              </button>
            )}
            {editMode && (
              <button
                className="btn blueroundedbtn"
                onClick={() => {
                  updateStudentInfo();
                }}
              >
                Save
              </button>
            )}
            {!editMode && (
              <button
                className="btn blueroundedbtn"
                onClick={() => {
                  setEditMode(false);
                  showHideModal(false);
                }}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default StudentDetailModal;
