import React from 'react';

function UpdateMeetingSettingsAuth() {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.7066 18.3289C33.7066 18.7905 33.5233 19.2332 33.1969 19.5595C32.8705 19.8859 32.4279 20.0693 31.9663 20.0693H18.0438C17.5822 20.0693 17.1396 19.8859 16.8132 19.5595C16.4868 19.2332 16.3035 18.7905 16.3035 18.3289C16.3035 17.8674 16.4868 17.4247 16.8132 17.0984C17.1396 16.772 17.5822 16.5886 18.0438 16.5886H31.9663C32.4279 16.5886 32.8705 16.772 33.1969 17.0984C33.5233 17.4247 33.7066 17.8674 33.7066 18.3289ZM31.9663 23.5499H18.0438C17.5822 23.5499 17.1396 23.7332 16.8132 24.0596C16.4868 24.386 16.3035 24.8286 16.3035 25.2902C16.3035 25.7518 16.4868 26.1944 16.8132 26.5208C17.1396 26.8472 17.5822 27.0305 18.0438 27.0305H31.9663C32.4279 27.0305 32.8705 26.8472 33.1969 26.5208C33.5233 26.1944 33.7066 25.7518 33.7066 25.2902C33.7066 24.8286 33.5233 24.386 33.1969 24.0596C32.8705 23.7332 32.4279 23.5499 31.9663 23.5499ZM42.4082 4.40641V39.2127C42.4072 40.1355 42.0401 41.0202 41.3876 41.6728C40.7351 42.3253 39.8504 42.6923 38.9276 42.6934H4.12126C3.19845 42.6923 2.31374 42.3253 1.66122 41.6728C1.0087 41.0202 0.641661 40.1355 0.640625 39.2127V4.40641C0.641661 3.48361 1.0087 2.5989 1.66122 1.94638C2.31374 1.29386 3.19845 0.926818 4.12126 0.925781H38.9276C39.8504 0.926818 40.7351 1.29386 41.3876 1.94638C42.0401 2.5989 42.4072 3.48361 42.4082 4.40641ZM4.12126 39.2127H9.3422V4.40641H4.12126V39.2127ZM38.9297 39.2127L38.9276 4.40641H12.8228V39.2127H38.9276H38.9297Z"
        fill="#999999"
      />
    </svg>
  );
}

export default UpdateMeetingSettingsAuth;
