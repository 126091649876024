import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={18}
      height={24}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9512 21.8212L14.0271 23.8786H15.9404L14.1418 20.4375C13.7853 20.9307 13.387 21.3935 12.9512 21.8212Z"
        fill="#ffffff"
      />
      <path
        d="M11.0693 14.3924C11.0693 14.3924 6.81292 13.5138 5.59869 10.416H5.28079L0 23.8906C3.2556 24.4922 6.67457 22.5574 8.00065 19.1725C8.40184 18.155 8.58084 17.0663 8.52608 15.9767C8.99848 17.4404 8.98302 19.0136 8.48193 20.4682L10.2569 23.879H12.1702L11.1253 21.875C11.714 21.5864 16.8652 18.7945 16.8652 10.416H16.5267C15.3081 13.469 11.0693 14.3924 11.0693 14.3924Z"
        fill="#ffffff"
      />
      <path
        d="M11.0833 12.1606C11.3978 12.1606 11.6528 11.6516 11.6528 11.0237C11.6528 10.3957 11.3978 9.88672 11.0833 9.88672C10.7687 9.88672 10.5137 10.3957 10.5137 11.0237C10.5137 11.6516 10.7687 12.1606 11.0833 12.1606Z"
        fill="#ffffff"
      />
      <path
        d="M11.4646 7.73212C11.77 7.33348 12.1976 7.04122 12.6848 6.89816L14.4509 6.3773V3.56234L12.8157 3.079L9.75589 2.17146L11.0805 1.78045V1.77035L15.2206 3.00253V6.14356L16.4069 5.79296V4.66755V2.79187L17.9979 2.3143V2.04737L11.0805 0L4.16309 2.04737V2.3143L5.75262 2.78466V4.66034V5.7843L9.48066 6.89816C9.96881 7.04313 10.3965 7.33801 10.7008 7.73933"
        fill="#ffffff"
      />
      <path
        d="M5.75586 7.73242V8.61543C5.87222 8.61616 5.98514 8.65422 6.07736 8.72378C6.16958 8.79335 6.23604 8.89059 6.26657 9.00067L6.40345 9.48834C6.51069 9.88724 6.73537 10.2464 7.04945 10.5209C7.36352 10.7954 7.75311 10.9733 8.1696 11.0322C8.66286 11.0981 9.16404 10.9915 9.58504 10.731C10.006 10.4706 10.3199 10.073 10.4715 9.6081L10.6069 9.19112C10.6342 9.10274 10.6859 9.02348 10.7563 8.96212C10.8267 8.90075 10.913 8.85966 11.0057 8.8434C11.1226 8.82472 11.2425 8.8478 11.3435 8.90843C11.4445 8.96907 11.52 9.06324 11.5562 9.17381L11.6857 9.57347C11.8101 9.96494 12.0484 10.3123 12.3713 10.5729C12.6941 10.8336 13.0876 10.9962 13.5034 11.0408C14.0016 11.0898 14.5014 10.9641 14.914 10.6859C15.3265 10.4077 15.6251 9.99515 15.7567 9.52153L15.9039 9.00067C15.9349 8.8906 16.0017 8.79344 16.0941 8.72393C16.1865 8.65441 16.2996 8.61632 16.416 8.61543V7.73242H11.4679"
        fill="#ffffff"
      />
    </svg>
  );
}

export default SvgComponent;
