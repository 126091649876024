import React from 'react';

function EmailGray() {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.0016 0.430176H18.0102C19.4261 0.430176 20.1397 1.09848 20.1397 2.45776V11.9953C20.1397 13.3433 19.4261 14.0229 18.0102 14.0229H3.0016C1.58569 14.0229 0.87207 13.3433 0.87207 11.9953V2.45776C0.87207 1.09848 1.58569 0.430176 3.0016 0.430176ZM10.5002 10.1716L18.1348 3.90764C18.4067 3.6811 18.6219 3.16005 18.2821 2.69563C17.9536 2.23121 17.3532 2.21988 16.9568 2.50306L10.5002 6.87539L4.05503 2.50306C3.65858 2.21988 3.05823 2.23121 2.72974 2.69563C2.38992 3.16005 2.60514 3.6811 2.877 3.90764L10.5002 10.1716Z"
        fill="#797979"
      />
    </svg>
  );
}

export default EmailGray;
