import React from 'react';

function GroupIconPvtNav({ color, tooltip, place }) {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      data-tip={tooltip}
      data-place={place}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 10C11.913 10 12.5489 10.2634 13.0178 10.7322C13.4866 11.2011 13.75 11.837 13.75 12.5V14.375C13.75 16.9425 11.14 18.75 6.875 18.75C2.61 18.75 0 16.9425 0 14.375V12.5C0 11.837 0.263392 11.2011 0.732233 10.7322C1.20107 10.2634 1.83696 10 2.5 10H11.25ZM17.5 10C18.163 10 18.7989 10.2634 19.2678 10.7322C19.7366 11.2011 20 11.837 20 12.5V13.125C20 15.7362 18.04 17.5 14.375 17.5C14.1987 17.5 14.025 17.4963 13.8575 17.4875C14.5313 16.7038 14.93 15.755 14.9913 14.6737L15 14.375V12.5C15 11.635 14.7075 10.8375 14.215 10.2038L14.0437 10H17.5ZM6.875 0C7.44953 -8.56121e-09 8.01844 0.113163 8.54924 0.333027C9.08004 0.552891 9.56234 0.875151 9.96859 1.28141C10.3748 1.68766 10.6971 2.16996 10.917 2.70076C11.1368 3.23156 11.25 3.80047 11.25 4.375C11.25 4.94953 11.1368 5.51844 10.917 6.04924C10.6971 6.58004 10.3748 7.06234 9.96859 7.46859C9.56234 7.87485 9.08004 8.19711 8.54924 8.41697C8.01844 8.63684 7.44953 8.75 6.875 8.75C5.71468 8.75 4.60188 8.28906 3.78141 7.46859C2.96094 6.64812 2.5 5.53532 2.5 4.375C2.5 3.21468 2.96094 2.10188 3.78141 1.28141C4.60188 0.460936 5.71468 1.72901e-08 6.875 0ZM15.625 2.5C16.4538 2.5 17.2487 2.82924 17.8347 3.41529C18.4208 4.00134 18.75 4.7962 18.75 5.625C18.75 6.4538 18.4208 7.24866 17.8347 7.83471C17.2487 8.42076 16.4538 8.75 15.625 8.75C14.7962 8.75 14.0013 8.42076 13.4153 7.83471C12.8292 7.24866 12.5 6.4538 12.5 5.625C12.5 4.7962 12.8292 4.00134 13.4153 3.41529C14.0013 2.82924 14.7962 2.5 15.625 2.5Z"
        fill={color}
      />
    </svg>
  );
}
GroupIconPvtNav.defaultProps = {
  color: '#8E8E8E',
};

export default GroupIconPvtNav;
