import React from 'react';

function GoogleLogoAuth() {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.6909 25.0252H52.6883V24.922H30.3128V34.8667H44.3634C42.3135 40.6557 36.8054 44.8114 30.3128 44.8114C22.0749 44.8114 15.3958 38.1323 15.3958 29.8944C15.3958 21.6565 22.0749 14.9774 30.3128 14.9774C34.1154 14.9774 37.5749 16.4119 40.209 18.7551L47.2411 11.723C42.8008 7.58476 36.8614 5.03271 30.3128 5.03271C16.583 5.03271 5.45117 16.1645 5.45117 29.8944C5.45117 43.6242 16.583 54.756 30.3128 54.756C44.0427 54.756 55.1745 43.6242 55.1745 29.8944C55.1745 28.2274 55.0029 26.6002 54.6909 25.0252Z"
        fill="#FFC107"
      />
      <path
        d="M8.32031 18.3225L16.4886 24.3129C18.6988 18.8409 24.0515 14.9774 30.3154 14.9774C34.118 14.9774 37.5775 16.4119 40.2116 18.7551L47.2437 11.723C42.8034 7.58476 36.864 5.03271 30.3154 5.03271C20.7661 5.03271 12.4846 10.424 8.32031 18.3225Z"
        fill="#FF3D00"
      />
      <path
        d="M30.3147 54.7563C36.7365 54.7563 42.5715 52.2987 46.9832 48.3022L39.2885 41.7909C36.7086 43.753 33.556 44.8142 30.3147 44.8116C23.8482 44.8116 18.3575 40.6883 16.289 34.9341L8.18164 41.1806C12.2962 49.232 20.6522 54.7563 30.3147 54.7563Z"
        fill="#4CAF50"
      />
      <path
        d="M54.6925 25.0255H52.6899V24.9224H30.3145V34.867H44.365C43.3845 37.6222 41.6182 40.0298 39.2845 41.7922L39.2883 41.7898L46.983 48.301C46.4385 48.7958 55.1761 42.3255 55.1761 29.8947C55.1761 28.2277 55.0046 26.6005 54.6925 25.0255Z"
        fill="#1976D2"
      />
    </svg>
  );
}

export default GoogleLogoAuth;
