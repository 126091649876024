import React, { useEffect, useState } from 'react';
import EditIcon from '../components/svg/editIcon';
import PhoneReceiverIcon from '../components/svg/phoneReceiverIcon';
import { getStudentMeetingsById } from '../modals';
import StudentMeetingsEditModal from './modal/studentMeetingsEditModal';
import NoMeetingHeroImage from '../components/svg/noMeetingHeroImage';
import WebCamIcon from '../components/svg/webCamIcon';
import { format } from 'date-fns';
import InpersonIcon from '../components/svg/inpersonIcon';
function StudentMeetings({ sId }) {
  const { id: cId } = JSON.parse(localStorage.getItem('userinfo'));
  const [studentMeetings, setStudentMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showMeetingEditModal, setShowMeetingEditModal] = useState(false);
  const showHideMeetingEditModal = (bool) => {
    setShowMeetingEditModal(bool);
    !bool && setSelectedMeeting({});
  };
  const loadMeetings = async () => {
    setIsLoading(true);
    try {
      const result = await getStudentMeetingsById(cId, sId);
      setStudentMeetings(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error?.message || 'student meeting loading error');
    }
  };

  const updateMeetingList = (index, obj) => {
    const data = [...studentMeetings];
    data[index] = obj;
    setStudentMeetings(data);
  };
  useEffect(() => {
    loadMeetings();
  }, []);
  if (isLoading) {
    return <h6>{'Loading Meetings...'}</h6>;
  }
  if (studentMeetings.length === 0) {
    return (
      <div className="noMeetingWrapper">
        <div className="nomeetingImage">
          <NoMeetingHeroImage width={200} height={200} />
        </div>
        <h3>No meetings scheduled with student.</h3>
      </div>
    );
  }
  return (
    <>
      <div className="main studentMeetings">
        {studentMeetings.length > 0 &&
          studentMeetings.map((meeting, index) => {
            const {
              meetingDate,
              counsellorName,
              meetingTypeDesc,
              sessionTime,
              studentNotes,
              counsellorNnotes,
              meetingStartTime,
              meetingReasonTypeDesc,
              meetingLink,
              meetingType,
            } = meeting;
            return (
              <div className="meetingRow">
                <div className="meetingContainer">
                  <span
                    className="editIcon"
                    onClick={() => {
                      setSelectedMeeting({ ...meeting, index });
                      showHideMeetingEditModal(true);
                    }}
                  >
                    <EditIcon color="#BDBDBD" />
                  </span>
                  <h3 className="datetxt">
                    {format(new Date(meetingDate), 'MMMM dd,yyyy')}
                    <span className="timetxt">
                      <i>
                        {meetingType === 2 && <PhoneReceiverIcon />}
                        {meetingType === 1 && <WebCamIcon />}
                        {meetingType === 3 && <InpersonIcon />}
                      </i>
                      {meetingStartTime.split(':')[0]}:
                      {meetingStartTime.split(':')[1]}
                      {meetingStartTime.split(':')[0] < 12 ? ' am' : ' pm'}
                    </span>
                  </h3>

                  <div className="meetingColumn">
                    <div className="column firstColumn">
                      <ul className="MeetingDetails">
                        <li className="infoItem">
                          <p className="superTitle">Counselor</p>
                          <h3>{counsellorName}</h3>
                        </li>
                        <li className="infoItem">
                          <p className="superTitle">Meeting Reason</p>
                          <h3>{meetingReasonTypeDesc}</h3>
                        </li>
                        <li className="infoItem">
                          <p className="superTitle">Meeting Type</p>
                          <h3>{meetingTypeDesc}</h3>
                        </li>
                        <li className="infoItem">
                          <p className="superTitle">Meeting Link</p>
                          <h3>{meetingLink}</h3>
                        </li>

                        <li className="infoItem">
                          <p className="superTitle">Session Time</p>
                          <h3>{sessionTime}</h3>
                        </li>
                      </ul>
                    </div>

                    <div className="column secondColumn">
                      <ul className="MeetingDetails">
                        <li className="infoItem">
                          <p className="superTitle">Student Note</p>
                          <h3>{studentNotes}</h3>
                        </li>
                        <li className="infoItem">
                          <p className="superTitle">Counselor Note</p>
                          <h3>{counsellorNnotes}</h3>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {showMeetingEditModal && (
        <StudentMeetingsEditModal
          show={showMeetingEditModal}
          showHideModal={showHideMeetingEditModal}
          selectedMeeting={selectedMeeting}
          updateMeetingList={updateMeetingList}
        />
      )}
    </>
  );
}

export default StudentMeetings;
