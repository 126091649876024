import React from 'react';

function MeetingTypePhone({ width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.67536 5.72011L6.57428 2.14053C6.21678 1.72803 5.56136 1.72986 5.14611 2.14603L2.59595 4.70078C1.83695 5.46069 1.6197 6.58911 2.05878 7.49386C4.68193 12.9251 9.06216 17.3114 14.4897 19.9422C15.3935 20.3813 16.521 20.164 17.28 19.4041L19.854 16.8255C20.2711 16.4084 20.272 15.7494 19.8559 15.3919L16.2625 12.3073C15.8867 11.9846 15.3028 12.0268 14.926 12.4044L13.6757 13.6566C13.6117 13.7237 13.5274 13.7679 13.4359 13.7825C13.3443 13.7971 13.2505 13.7812 13.1688 13.7373C11.125 12.5604 9.42969 10.8628 8.25545 8.81753C8.21147 8.73571 8.19555 8.64173 8.21012 8.55C8.22469 8.45827 8.26895 8.37385 8.33611 8.30969L9.58278 7.06211C9.96045 6.68261 10.0017 6.09594 9.67536 5.71919V5.72011Z"
        stroke="#999999"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MeetingTypePhone;
