import React from 'react';

function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.60495 0.44694C2.00904 -0.14898 1.04286 -0.14898 0.44694 0.44694C-0.14898 1.04286 -0.14898 2.00904 0.44694 2.60495L5.84199 8L0.44694 13.395C-0.14898 13.991 -0.14898 14.9571 0.44694 15.5531C1.04286 16.149 2.00904 16.149 2.60495 15.5531L8 10.158L13.395 15.553C13.991 16.149 14.9571 16.149 15.553 15.553C16.149 14.9571 16.149 13.991 15.553 13.395L10.158 8L15.553 2.60497C16.149 2.00905 16.149 1.04287 15.553 0.446954C14.9571 -0.148966 13.991 -0.148966 13.395 0.446954L8 5.84199L2.60495 0.44694Z"
        fill="#CDCDCD"
      />
    </svg>
  );
}

export default CloseIcon;
