import React from 'react';

function NotesIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5189 9.17495C14.5189 9.34005 14.4533 9.49838 14.3366 9.61513C14.2198 9.73187 14.0615 9.79745 13.8964 9.79745H8.91641C8.75131 9.79745 8.59297 9.73187 8.47623 9.61513C8.35949 9.49838 8.29391 9.34005 8.29391 9.17495C8.29391 9.00985 8.35949 8.85152 8.47623 8.73478C8.59297 8.61804 8.75131 8.55245 8.91641 8.55245H13.8964C14.0615 8.55245 14.2198 8.61804 14.3366 8.73478C14.4533 8.85152 14.5189 9.00985 14.5189 9.17495ZM13.8964 11.0425H8.91641C8.75131 11.0425 8.59297 11.108 8.47623 11.2248C8.35949 11.3415 8.29391 11.4999 8.29391 11.665C8.29391 11.83 8.35949 11.9884 8.47623 12.1051C8.59297 12.2219 8.75131 12.2875 8.91641 12.2875H13.8964C14.0615 12.2875 14.2198 12.2219 14.3366 12.1051C14.4533 11.9884 14.5189 11.83 14.5189 11.665C14.5189 11.4999 14.4533 11.3415 14.3366 11.2248C14.2198 11.108 14.0615 11.0425 13.8964 11.0425ZM17.6314 4.19495V16.645C17.631 16.975 17.4997 17.2915 17.2663 17.5249C17.0329 17.7583 16.7165 17.8896 16.3864 17.89H3.93641C3.60633 17.8896 3.28987 17.7583 3.05647 17.5249C2.82307 17.2915 2.69178 16.975 2.69141 16.645V4.19495C2.69178 3.86487 2.82307 3.54842 3.05647 3.31501C3.28987 3.08161 3.60633 2.95032 3.93641 2.94995H16.3864C16.7165 2.95032 17.0329 3.08161 17.2663 3.31501C17.4997 3.54842 17.631 3.86487 17.6314 4.19495ZM3.93641 16.645H5.80391V4.19495H3.93641V16.645ZM16.3872 16.645L16.3864 4.19495H7.04891V16.645H16.3864H16.3872Z"
        fill="#999999"
      />
    </svg>
  );
}

export default NotesIcon;
