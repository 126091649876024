import axios from 'axios';
import baseUrl from '../baseUrl/baseUrl';

const instance = axios.create();
const request = (method, url, data) =>
  new Promise((resolve, reject) => {
    instance.defaults.baseURL = baseUrl.url;
    (() =>
      instance.request({
        url,
        method,
        data,
      }))()
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const { data: error } = err.response || {};
        reject(error);
      });
  });
export const requestNoToken = {
  get: (endpoint, data) => request('get', endpoint, data),
  post: (endpoint, data, headers) => request('post', endpoint, data, headers),
  patch: (endpoint, data) => request('patch', endpoint, data),
};
