import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={167}
      height={110}
      viewBox="0 0 167 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 100H129C132.866 100 136 96.866 136 93C136 89.134 132.866 86 129 86C129 86 123 82.866 123 79C123 75.134 126.952 72 131.826 72H142C145.866 72 149 68.866 149 65C149 61.134 145.866 58 142 58H120C123.866 58 127 54.866 127 51C127 47.134 123.866 44 120 44H160C163.866 44 167 40.866 167 37C167 33.134 163.866 30 160 30H62C58.134 30 55 33.134 55 37C55 40.866 58.134 44 62 44H22C18.134 44 15 47.134 15 51C15 54.866 18.134 58 22 58H47C50.866 58 54 61.134 54 65C54 68.866 50.866 72 47 72H7C3.13401 72 0 75.134 0 79C0 82.866 3.13401 86 7 86H46C42.134 86 39 89.134 39 93C39 96.866 42.134 100 46 100ZM153 65C153 68.866 156.134 72 160 72C163.866 72 167 68.866 167 65C167 61.134 163.866 58 160 58C156.134 58 153 61.134 153 65Z"
        fill="#F1F1F1"
      />
      <mask id="path-2-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66 90.9913C65.834 90.9971 65.6673 91 65.5 91C56.9396 91 50 83.3888 50 74C50 64.6112 56.9396 57 65.5 57C65.6673 57 65.834 57.0029 66 57.0087V47C66 44.7909 67.7909 43 70 43H111C113.209 43 115 44.7909 115 47V108C115 109.105 114.105 110 113 110H68C66.8954 110 66 109.105 66 108V90.9913ZM66 82.48V65.52C65.8348 65.5067 65.6681 65.5 65.5 65.5C61.5051 65.5 58.2667 69.3056 58.2667 74C58.2667 78.6944 61.5051 82.5 65.5 82.5C65.6681 82.5 65.8348 82.4933 66 82.48Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 90.9913C65.834 90.9971 65.6673 91 65.5 91C56.9396 91 50 83.3888 50 74C50 64.6112 56.9396 57 65.5 57C65.6673 57 65.834 57.0029 66 57.0087V47C66 44.7909 67.7909 43 70 43H111C113.209 43 115 44.7909 115 47V108C115 109.105 114.105 110 113 110H68C66.8954 110 66 109.105 66 108V90.9913ZM66 82.48V65.52C65.8348 65.5067 65.6681 65.5 65.5 65.5C61.5051 65.5 58.2667 69.3056 58.2667 74C58.2667 78.6944 61.5051 82.5 65.5 82.5C65.6681 82.5 65.8348 82.4933 66 82.48Z"
        fill="white"
      />
      <path
        d="M65.5 91V88.5V91ZM66 57.0087L65.9132 59.5072L68.5 59.5971V57.0087H66ZM66 65.52H68.5V63.2125L66.1999 63.028L66 65.52ZM65.9132 88.4928C65.7761 88.4976 65.6383 88.5 65.5 88.5V93.5C65.6963 93.5 65.8919 93.4966 66.0868 93.4898L65.9132 88.4928ZM65.5 88.5C58.5326 88.5 52.5 82.2305 52.5 74H47.5C47.5 84.5472 55.3465 93.5 65.5 93.5V88.5ZM52.5 74C52.5 65.7695 58.5326 59.5 65.5 59.5V54.5C55.3465 54.5 47.5 63.4528 47.5 74H52.5ZM65.5 59.5C65.6383 59.5 65.7761 59.5024 65.9132 59.5072L66.0868 54.5102C65.8919 54.5034 65.6963 54.5 65.5 54.5V59.5ZM68.5 57.0087V47H63.5V57.0087H68.5ZM68.5 47C68.5 46.1716 69.1716 45.5 70 45.5V40.5C66.4101 40.5 63.5 43.4101 63.5 47H68.5ZM70 45.5H111V40.5H70V45.5ZM111 45.5C111.828 45.5 112.5 46.1716 112.5 47H117.5C117.5 43.4101 114.59 40.5 111 40.5V45.5ZM112.5 47V108H117.5V47H112.5ZM112.5 108C112.5 107.724 112.724 107.5 113 107.5V112.5C115.485 112.5 117.5 110.485 117.5 108H112.5ZM113 107.5H68V112.5H113V107.5ZM68 107.5C68.2761 107.5 68.5 107.724 68.5 108H63.5C63.5 110.485 65.5147 112.5 68 112.5V107.5ZM68.5 108V90.9913H63.5V108H68.5ZM68.5 82.48V65.52H63.5V82.48H68.5ZM66.1999 63.028C65.9683 63.0094 65.7349 63 65.5 63V68C65.6012 68 65.7013 68.0041 65.8001 68.012L66.1999 63.028ZM65.5 63C59.7613 63 55.7667 68.3184 55.7667 74H60.7667C60.7667 70.2928 63.2489 68 65.5 68V63ZM55.7667 74C55.7667 79.6816 59.7613 85 65.5 85V80C63.2489 80 60.7667 77.7072 60.7667 74H55.7667ZM65.5 85C65.7349 85 65.9683 84.9906 66.1999 84.972L65.8001 79.988C65.7013 79.9959 65.6012 80 65.5 80V85Z"
        fill="#C1C1C1"
        mask="url(#path-2-inside-1)"
      />
      <path
        d="M106 52.1816H108.5C108.5 50.8009 107.381 49.6816 106 49.6816V52.1816ZM106 52.1816V49.6816C104.619 49.6816 103.5 50.8009 103.5 52.1816H106ZM106 70.5V52.1816H103.5V70.5H106ZM106 70.5H103.5C103.5 71.8807 104.619 73 106 73V70.5ZM106 70.5V73C107.381 73 108.5 71.8807 108.5 70.5H106ZM106 52.1816V70.5H108.5V52.1816H106ZM106 76.1816H108.5C108.5 74.8009 107.381 73.6816 106 73.6816V76.1816ZM106 76.1816V73.6816C104.619 73.6816 103.5 74.8009 103.5 76.1816H106ZM106 80.9248V76.1816H103.5V80.9248H106ZM106 80.9248H103.5C103.5 82.3055 104.619 83.4248 106 83.4248V80.9248ZM106 80.9248V83.4248C107.381 83.4248 108.5 82.3055 108.5 80.9248H106ZM106 76.1816V80.9248H108.5V76.1816H106Z"
        fill="#C1C1C1"
      />
      <path
        d="M32.1289 102.5V100C30.7482 100 29.6289 101.119 29.6289 102.5H32.1289ZM32.1289 102.5H29.6289C29.6289 103.881 30.7482 105 32.1289 105V102.5ZM39.001 102.5H32.1289V105H39.001V102.5ZM39.001 102.5V105C40.3817 105 41.501 103.881 41.501 102.5H39.001ZM39.001 102.5H41.501C41.501 101.119 40.3817 100 39.001 100V102.5ZM32.1289 102.5H39.001V100H32.1289V102.5ZM146.129 102.5V100C144.748 100 143.629 101.119 143.629 102.5H146.129ZM146.129 102.5H143.629C143.629 103.881 144.748 105 146.129 105V102.5ZM149.001 102.5H146.129V105H149.001V102.5ZM149.001 102.5V105C150.382 105 151.501 103.881 151.501 102.5H149.001ZM149.001 102.5H151.501C151.501 101.119 150.382 100 149.001 100V102.5ZM146.129 102.5H149.001V100H146.129V102.5ZM119.001 100C117.62 100 116.501 101.119 116.501 102.5H119.001V100ZM141.429 100H119.001V102.5H141.429V100ZM143.929 102.5C143.929 101.119 142.809 100 141.429 100V102.5H143.929ZM141.429 105C142.809 105 143.929 103.881 143.929 102.5H141.429V105ZM119.001 105H141.429V102.5H119.001V105ZM116.501 102.5C116.501 103.881 117.62 105 119.001 105V102.5H116.501ZM45.001 102.5V100C43.6203 100 42.501 101.119 42.501 102.5H45.001ZM45.001 102.5H42.501C42.501 103.881 43.6203 105 45.001 105V102.5ZM66.4551 102.5H45.001V105H66.4551V102.5ZM66.4551 102.5V105C67.8358 105 68.9551 103.881 68.9551 102.5H66.4551ZM66.4551 102.5H68.9551C68.9551 101.119 67.8358 100 66.4551 100V102.5ZM45.001 102.5H66.4551V100H45.001V102.5Z"
        fill="#C1C1C1"
      />
      <path
        d="M93 2C93 2 89 8.32031 89 13C89 18.5562 93.6538 21.0444 93.6538 27.063C93.6538 31.645 89 36.1621 89 36.1621"
        stroke="#C1C1C1"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M80.9991 13C80.1266 18.5127 83.9991 19.5322 83.9991 24.4746C83.9991 28.2373 80.9991 32 80.9991 32"
        stroke="#C1C1C1"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M99.7945 10.4741C98.4735 13.8933 99.2156 15.9774 99.7945 17.2041C101.032 19.8262 102.999 22.0896 102.999 24.7578C102.999 29.4062 99.7945 33.7051 99.7945 33.7051"
        stroke="#C1C1C1"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
