import React, { useRef } from 'react';
import LeftArrow from '../components/svg/leftArrow';
import RightArrow from '../components/svg/rightArrow';
import CalendarIcon from '../components/svg/calendarIcon';
import { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useState } from 'react';
import { format, addDays, subDays } from 'date-fns';
import MeetingInfoModal from './modal/meetingInfoModal';
import { getMeeetingDetails } from '../modals';

function CalendarSchedule({ mData }) {
  const calendarRef = useRef();
  const [meetingsArray, setMeetingsArray] = useState([]);
  const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [meetingData, setMeetingData] = useState([]);
  const [clickedMeeting, setClickedMeeting] = useState({});

  const showHideMeetingModal = (bool) => {
    setShowMeetingModal(bool);
    if (!bool) {
      const meetingsarray = [...meetingsArray];
      const newMeetingArr = [];
      meetingsarray.map((item) => {
        newMeetingArr.push({
          ...item,
          color: '',
          textColor: '',
        });
      });
      setMeetingsArray(newMeetingArr);
    }
  };

  const getMeetingInfo = (meetingid) => {
    const [result] = meetingData.filter(({ meetingId }) => {
      return meetingId === parseInt(meetingid);
    });
    setClickedMeeting(result);
    showHideMeetingModal(true);
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <p style={{ fontWeight: 'bold' }}>{eventInfo.event.title}, </p> &nbsp;
        <p>{format(eventInfo.event.start, 'hh:mm a')}</p>
      </>
    );
  }
  const handleEventClick = (clickInfo) => {
    const selectedId = clickInfo.event.id;
    const meetingsarray = [...meetingsArray];
    const newMeetingArr = [];
    meetingsarray.map((item) => {
      const { id } = item;
      newMeetingArr.push({
        ...item,
        color: parseInt(selectedId) === id ? '#1367B2' : '',
        textColor: parseInt(selectedId) === id ? 'white' : '',
      });
    });
    setMeetingsArray(newMeetingArr);
    getMeetingInfo(clickInfo.event.id);
  };
  const createMeetingArray = (data) => {
    let meetingarr = [];
    if (data.length > 0) {
      data.map((item) => {
        const {
          meetingStartTime,
          meetingEndTime,
          meetingDate,
          meetingId,
          studentDetail,
        } = item;
        meetingarr.push({
          start: `${meetingDate}T${
            meetingStartTime.split(':')[0] +
            ':' +
            meetingStartTime.split(':')[1]
          }`,
          end: `${meetingDate}T${
            meetingEndTime.split(':')[0] + ':' + meetingEndTime.split(':')[1]
          }`,
          id: meetingId,
          title: studentDetail[0]?.fullName,
        });
      });
    }
    setMeetingsArray(meetingarr);
    console.log('meetingsarr', meetingarr);
  };
  const loadMeetingDetails = async (data) => {
    try {
      const result = await getMeeetingDetails(data);
      setMeetingData(result);
      createMeetingArray(result);
    } catch (error) {
      console.log('meeting details', error);
    }
  };
  useEffect(() => {
    setMeetingData(mData);
    createMeetingArray(mData);
    // const calendarApi = calendarRef.current.getApi();
    // loadMeetingDetails({
    //   startDate: format(calendarApi.getDate(), 'yyyy-MM-dd'),
    //   endDate: format(calendarApi.getDate(), 'yyyy-MM-dd'),
    // });
  }, [mData]);
  const rightClick = () => {
    setCurrentDate(new Date(addDays(currentDate, 1)));
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    loadMeetingDetails({
      startDate: format(new Date(addDays(currentDate, 1)), 'yyyy-MM-dd'),
      endDate: format(new Date(addDays(currentDate, 1)), 'yyyy-MM-dd'),
    });
  };
  const leftClick = () => {
    setCurrentDate(new Date(subDays(currentDate, 1)));
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    loadMeetingDetails({
      startDate: format(new Date(subDays(currentDate, 1)), 'yyyy-MM-dd'),
      endDate: format(new Date(subDays(currentDate, 1)), 'yyyy-MM-dd'),
    });
  };
  return (
    <>
      <div className="studentScheRightPane">
        <div className="calanderHeader">
          <div className="dayanddate">
            <div>
              <h4>{format(currentDate, 'EEEE')}</h4>
              <h6>{format(currentDate, 'MMMM dd, yyyy')}</h6>
            </div>
            <div className="rightandleftaarorw">
              <span
                onClick={() => {
                  leftClick();
                }}
              >
                <LeftArrow />
              </span>
              <span
                onClick={() => {
                  rightClick();
                }}
              >
                <RightArrow />
              </span>
            </div>
          </div>
          <div className="calandericon">
            <CalendarIcon />
          </div>
        </div>
        <div className="calanderbody meetings-dashbrd">
          <div className="calanderul">
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              slotMinTime="06:00:00"
              slotMaxTime="23:00:00"
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              dayHeaders={false}
              allDaySlot={false}
              headerToolbar={false}
              initialView="timeGridDay"
              weekends={true}
              events={meetingsArray}
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              eventBackgroundColor="#DBEDFF"
              eventBorderColor="transparent"
              eventTextColor="#1D5EA6"
              height="auto"
            />
          </div>
        </div>
      </div>
      {showMeetingModal && (
        <MeetingInfoModal
          show={showMeetingModal}
          showHideModal={showHideMeetingModal}
          meetingInfo={clickedMeeting}
          fromTitle="Home"
          fromLink="/home"
        />
      )}
    </>
  );
}

export default CalendarSchedule;
