import { Request } from '../utils';

export const getSSODetails = async () => {
  const result = await Request.get('calendar/sso-details');
  return result;
};
export const saveAuthToken = async (data) => {
  const result = await Request.post('calendar/auth-tokens', data);
  return result;
};
