import React from 'react';

function CalendarIcon() {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2437 2.06187H19.0296V0.0292969H16.9226V2.06187H8.49451V0.0292969H6.38748V2.06187H2.17343C1.61478 2.06241 1.07918 2.27673 0.684156 2.65779C0.289133 3.03886 0.0669641 3.55554 0.0664062 4.09444V24.4202C0.0669641 24.9591 0.289133 25.4758 0.684156 25.8568C1.07918 26.2379 1.61478 26.4522 2.17343 26.4528H23.2437C23.8023 26.4522 24.3379 26.2379 24.733 25.8568C25.128 25.4758 25.3502 24.9591 25.3507 24.4202V4.09444C25.3502 3.55554 25.128 3.03886 24.733 2.65779C24.3379 2.27673 23.8023 2.06241 23.2437 2.06187ZM2.17343 4.09444H6.38748V6.12702H8.49451V4.09444H16.9226V6.12702H19.0296V4.09444H23.2437V8.15959H2.17343V4.09444ZM2.17343 10.1922H7.44099V16.2899H2.17343V10.1922ZM15.8691 24.4202H9.54802V18.3225H15.8691V24.4202ZM15.8691 16.2899H9.54802V10.1922H15.8691V16.2899ZM17.9761 24.4202V18.3225H23.2437L23.2447 24.4202H17.9761Z"
        fill="#BABABA"
      />
    </svg>
  );
}

export default CalendarIcon;
