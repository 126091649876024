import React, { useEffect } from 'react';
import Modal from 'react-modal';
import PdfFullViewer from '../pdfFullViewer';
import CloseIconBig from '../../components/svg/closeIconBig';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    height: '80vh',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
    padding: '20px 20px 80px 20px',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function StudentDocFullViewer({
  show,
  showHideModal,
  url: { fileName, preSignedURL },
  selectedFileType,
}) {
  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <div className="popheader2">
          <span className="popheaderspan">
            <a className="viewfile" href={url} target="_blank">
              View in different tab
            </a>
          </span>
        </div> */}
        <div class="content">
          <div className="titleTxtPdf">
            {fileName}
            <span className="closepdfbtn" onClick={() => showHideModal(false)}>
              <CloseIconBig />
            </span>
          </div>

          {selectedFileType === 'pdf' && <PdfFullViewer pdf={preSignedURL} />}
          {selectedFileType === 'image' && (
            <img style={{ width: '100%', height: 'auto' }} src={preSignedURL} />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default StudentDocFullViewer;
