import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../components/svg/closeIcon';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { editStudentMeetings } from '../../modals';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '900px',
    borderRadius: '8px',
    padding: '20px 20px 100px 20px',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function StudentMeetingsEditModal({
  show,
  showHideModal,
  selectedMeeting,
  updateMeetingList,
}) {
  const [apiResponse, setApiResponse] = useState('');
  const REASON_TEXTS = ['Academic', ' Career', 'Financial', 'Personal'];
  const {
    meetingDate,
    counsellorName,
    meetingTypeDesc,
    sessionTime,
    studentNotes,
    counsellorNnotes,
    meetingStartTime,
    meetingReasonTypeDesc,
    meetingLink,
    meetingType,
    meetingReasonType,
    meetingId,
    index,
  } = selectedMeeting;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onSubmit',
  });
  const updateMeeting = async (data) => {
    try {
      await editStudentMeetings(data);
      setApiResponse('Meeting updated successfully!');
      const meetingupdated = {
        ...selectedMeeting,
        meetingReasonTypeDesc: REASON_TEXTS[data.meetingReasonType - 1],
        meetingReasonType: data.meetingReasonType,
        counsellorNnotes: data.counselorNote,
      };
      updateMeetingList(index, meetingupdated);
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    } catch (error) {
      setApiResponse(error?.message || 'Failed to update');
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
      console.log(error?.message || 'edit student meeting failed');
    }
  };
  const submit = handleSubmit((data) => {
    updateMeeting({
      meetingId,
      meetingReasonType: parseInt(data.meetingreson),
      counselorNote: data.noteTostudent,
    });
  });
  useEffect(() => {
    setValue('meetingreson', meetingReasonType);
    setValue('noteTostudent', counsellorNnotes);
  }, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popheader">
          <h2>Edit Meeting Details </h2>

          <h4> {format(new Date(meetingDate), 'MMMM dd,yyyy')}</h4>
          <span className="popheaderspan">
            <button
              className="transpBtn"
              onClick={() => {
                showHideModal(false);
              }}
            >
              <CloseIcon />
            </button>
          </span>
        </div>
        <div class="content editModalContent">
          <ul className="studentDetailss meetings">
            <li>
              <div className="detailitem">
                <p className="superTitle">Counselor</p>
                <h3>{counsellorName}</h3>
              </div>
            </li>
            <li>
              <div className="detailitem">
                <p className="superTitle">Meeting reason</p>
                <div className="popselect custom-select">
                  <select {...register('meetingreson')}>
                    <option value="">select</option>
                    <option value={1}>Academic</option>
                    <option value={2}>Career</option>
                    <option value={3}>Financial</option>
                    <option value={4}>Personal</option>
                  </select>
                </div>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Meeting Type</p>
                <p>{meetingTypeDesc}</p>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Meeting Link</p>
                <p>{meetingLink}</p>
              </div>
            </li>

            <li>
              <div className="detailitem">
                <p className="superTitle">Session Time</p>
                <p>{sessionTime}</p>
              </div>
            </li>
          </ul>
          <ul className="meetingsNotescolmn">
            <li>
              <div className="detailitem">
                <p className="superTitle">Student Note</p>
                <p className="noteScoll">{studentNotes}</p>
              </div>
            </li>
            <li>
              <div className="detailitem">
                <p className="superTitle">Counselor Note</p>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  {...register('noteTostudent')}
                />
              </div>
            </li>
          </ul>
        </div>

        <div className="popfooter">
          {apiResponse && <div class="errorMessage">{apiResponse}</div>}
          <div className="btnwrapper">
            <button
              className="btn transparentbtn"
              onClick={() => {
                showHideModal(false);
              }}
            >
              Cancel
            </button>

            <button
              className="btn blueroundedbtn"
              onClick={() => {
                submit();
              }}
            >
              Save
            </button>
          </div>{' '}
        </div>
      </Modal>
    </div>
  );
}

export default StudentMeetingsEditModal;
