import React, { useEffect, useRef, useState } from 'react';
import '../css/student.css';
import DownUpArrow from '../components/svg/downUpArrow';

function StudentClasses({
  data,
  classesSorting,
  sortedBy: { sortedField, order },
}) {
  return (
    <>
      <div className="studentclasseswrapper">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th
                  style={{ cursor: 'pointer' }}
                  className={sortedField === 'year' ? 'selectedTh' : ''}
                  onClick={() => {
                    classesSorting(
                      'year',
                      sortedField === 'year'
                        ? order === 'descending'
                          ? 'ascending'
                          : 'descending'
                        : 'ascending'
                    );
                  }}
                >
                  Term{' '}
                  {sortedField === 'year' && (
                    <DownUpArrow rotateUp={order === 'descending'} />
                  )}
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  className={sortedField === 'className' ? 'selectedTh' : ''}
                  onClick={() => {
                    classesSorting(
                      'className',
                      sortedField === 'className'
                        ? order === 'descending'
                          ? 'ascending'
                          : 'descending'
                        : 'ascending'
                    );
                  }}
                >
                  Course{' '}
                  {sortedField === 'className' && (
                    <DownUpArrow rotateUp={order === 'descending'} />
                  )}
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  className={sortedField === 'grade' ? 'selectedTh' : ''}
                  onClick={() => {
                    classesSorting(
                      'grade',
                      sortedField === 'grade'
                        ? order === 'descending'
                          ? 'ascending'
                          : 'descending'
                        : 'ascending'
                    );
                  }}
                >
                  Grade{' '}
                  {sortedField === 'grade' && (
                    <DownUpArrow rotateUp={order === 'descending'} />
                  )}
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  className={sortedField === 'units' ? 'selectedTh' : ''}
                  onClick={() => {
                    classesSorting(
                      'units',
                      sortedField === 'units'
                        ? order === 'descending'
                          ? 'ascending'
                          : 'descending'
                        : 'ascending'
                    );
                  }}
                >
                  Units{' '}
                  {sortedField === 'units' && (
                    <DownUpArrow rotateUp={order === 'descending'} />
                  )}
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  className={sortedField === 'school' ? 'selectedTh' : ''}
                  onClick={() => {
                    classesSorting(
                      'school',
                      sortedField === 'school'
                        ? order === 'descending'
                          ? 'ascending'
                          : 'descending'
                        : 'ascending'
                    );
                  }}
                >
                  College{' '}
                  {sortedField === 'school' && (
                    <DownUpArrow rotateUp={order === 'descending'} />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center' }}>
                    <h3>No Clasess Found</h3>
                  </td>
                </tr>
              ) : (
                data.map(
                  (
                    { semester, year, className, grade, units, school },
                    index
                  ) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {(semester || year) && (
                          <span
                            className={
                              semester === 'Spring'
                                ? 'classtag spr-green'
                                : semester === 'Fall'
                                ? 'classtag wi-blue'
                                : 'classtag fa-yellow'
                            }
                          >
                            {(semester || '--') + ' ' + (year || '--')}
                          </span>
                        )}
                        {!semester && !year && '--'}
                      </td>
                      <td>{className || '--'}</td>
                      <td>{grade || '--'}</td>
                      <td>{units || '--'}</td>
                      <td>{school || '--'}</td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default StudentClasses;
