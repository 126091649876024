import React, { useEffect } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    padding: '20px 20px 80px 20px',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function StudentContactModal({
  show,
  showHideModal,
  contactInfo: { phoneNumber, userFullName, email },
}) {
  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popheader">
          <h2>Contact {userFullName}</h2>
          <span className="popheaderspan">
            <button
              className="transpBtn"
              onClick={() => {
                showHideModal(false);
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.60495 0.44694C2.00904 -0.14898 1.04286 -0.14898 0.44694 0.44694C-0.14898 1.04286 -0.14898 2.00904 0.44694 2.60495L5.84199 8L0.44694 13.395C-0.14898 13.991 -0.14898 14.9571 0.44694 15.5531C1.04286 16.149 2.00904 16.149 2.60495 15.5531L8 10.158L13.395 15.553C13.991 16.149 14.9571 16.149 15.553 15.553C16.149 14.9571 16.149 13.991 15.553 13.395L10.158 8L15.553 2.60497C16.149 2.00905 16.149 1.04287 15.553 0.446954C14.9571 -0.148966 13.991 -0.148966 13.395 0.446954L8 5.84199L2.60495 0.44694Z"
                  fill="#CDCDCD"
                />
              </svg>
            </button>
          </span>
        </div>
        <div class="content">
          <ul className="details">
            <li>
              <div className="detailitem">
                <p className="superTitle">Phone number</p>
                <h3>{phoneNumber || '--'}</h3>
              </div>
            </li>

            {/* <li>
              <div className="detailitem">
                <p className="superTitle">.edu email</p>
                <h3></h3>
              </div>
            </li> */}

            <li>
              <div className="detailitem">
                <p className="superTitle">Email</p>
                <h3>{email || '--'}</h3>
              </div>
            </li>
          </ul>
        </div>

        <div className="popfooter">
          <button
            onClick={() => {
              showHideModal(false);
            }}
            className="btn blueroundedbtn"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default StudentContactModal;
