import React from 'react';

function ProfileIconPvtNav({ color, tooltip, place }) {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      data-tip={tooltip}
      data-place={place}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10.6667C12.7072 10.6667 13.3855 10.9476 13.8856 11.4477C14.3857 11.9478 14.6667 12.6261 14.6667 13.3333V15.3333C14.6667 18.072 11.8827 20 7.33333 20C2.784 20 0 18.072 0 15.3333V13.3333C0 12.6261 0.280951 11.9478 0.781048 11.4477C1.28115 10.9476 1.95942 10.6667 2.66667 10.6667H12ZM7.33333 0C7.94617 -9.13196e-09 8.553 0.120707 9.11919 0.355229C9.68538 0.589751 10.1998 0.933495 10.6332 1.36683C11.0665 1.80018 11.4102 2.31462 11.6448 2.88081C11.8793 3.447 12 4.05383 12 4.66667C12 5.2795 11.8793 5.88634 11.6448 6.45252C11.4102 7.01871 11.0665 7.53316 10.6332 7.9665C10.1998 8.39984 9.68538 8.74358 9.11919 8.97811C8.553 9.21263 7.94617 9.33333 7.33333 9.33333C6.09566 9.33333 4.90867 8.84167 4.0335 7.9665C3.15833 7.09133 2.66667 5.90434 2.66667 4.66667C2.66667 3.42899 3.15833 2.242 4.0335 1.36683C4.90867 0.491665 6.09566 1.84428e-08 7.33333 0Z"
        fill={color}
      />
    </svg>
  );
}
ProfileIconPvtNav.defaultProps = {
  color: '#999999',
};
export default ProfileIconPvtNav;
