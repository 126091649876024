import { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './router/Routes';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import './css/common.css';
import './css/reset.css';
import { tokenRefresh } from './modals/user';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkToken, setCheckToken] = useState(null);
  const [showSweetAlert, setShowSweetAlert] = useState(false);

  const updateLoginStatus = (bool) => {
    setLoggedIn(bool);
  };

  const closeSweetAlertOK = () => {
    setShowSweetAlert(false);
    setTimeout(() => {
      localStorage.removeItem('loginTime');
      localStorage.removeItem('logintoken');
      localStorage.removeItem('userinfo');
      window.location.reload();
      clearInterval(checkToken);
    }, 300);
  };

  const sessionTimer = () => {
    if (localStorage.getItem('logintoken')) {
      setLoggedIn(true);
      if (
        Math.round(new Date().getTime() / 1000) >
        localStorage.getItem('loginTime')
      ) {
        console.log('session timed out showing sweet alert');

        setShowSweetAlert(true);
        return;
      }
      let checktoken = setInterval(async () => {
        if (localStorage.getItem('logintoken')) {
          setCheckToken(checktoken);
          if (
            Math.round(new Date().getTime() / 1000) >
            localStorage.getItem('loginTime')
          ) {
            console.log('session timed out showing sweet alert');

            setShowSweetAlert(true);
            return;
          }
          if (
            Math.round(new Date().getTime() / 1000) >=
            localStorage.getItem('tokenRefreshTime')
          ) {
            try {
              const data = await tokenRefresh();
              localStorage.setItem('logintoken', data.token);
              let time = Math.round(new Date().getTime() / 1000);
              localStorage.setItem('loginTime', time + 3300);
              localStorage.setItem('tokenRefreshTime', time + 1800);
              console.log('session timer token refresh');
            } catch (error) {
              console.log('session timer token refresh error', error);
            }
          }
        }
      }, 30000);
    } else {
      console.log('session timer loginotoken not set');
    }
  };
  useEffect(() => {
    sessionTimer();
  }, []);
  return (
    <>
      <SweetAlert
        show={showSweetAlert}
        title="Session Expired"
        text="Your session expired, please relogin"
        onConfirm={() => {
          closeSweetAlertOK();
        }}
      />
      <div
        className={
          localStorage.getItem('logintoken') || loggedIn
            ? 'pico-wrap fullwidth'
            : 'pico-wrap '
        }
      >
        <Router>
          <Routes
            updateLoginStatus={updateLoginStatus}
            loggedIn={loggedIn}
            sessionTimer={sessionTimer}
          />
        </Router>
      </div>
    </>
  );
}

export default App;
