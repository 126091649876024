import { Request, requestNoToken } from '../utils';

export const counsellorEmailVerification = async (data) => {
  const result = await requestNoToken.get(
    `counsellers-validation?email=${data.email}&emailToken=${data.emailToken}`
  );
  return result;
};

export const createCounsellorCred = async (data) => {
  const result = await requestNoToken.post('counsellers-cred', data);
  return result;
};
export const resetPasswordRequest = async (email) => {
  const result = await requestNoToken.patch(
    `counseller/forget-password/resend-mail?email=${email}`
  );
  return result;
};
export const getStudentList = async (data) => {
  const result = await Request.get('counseller/student-list', data);
  return result;
};
export const getStudentTaskList = async (data) => {
  const result = await Request.get('counseller/student-task', data);
  return result;
};
export const getAcademicStandings = async () => {
  const result = await Request.get('counseller/academic-standings');
  return result;
};
export const updateStudentDeatils = async (data) => {
  const result = await Request.put('counseller/student-details', data);
  return result;
};
export const getStudentDeatils = async (data) => {
  const result = await Request.get('counselor/student-details', data);
  return result;
};
