import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import ForgotPasswordComp from '../components/forgotPasswordComp';
import LoginImageComp from '../components/image/loginImageComp';
import LoginComp from '../components/loginComp';
import { resetPasswordRequest } from '../modals';
import { login } from '../modals/user';

const UserLogin = ({ updateLoginStatus, sessionTimer }) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [pswResetMailSending, setPswResetMailSending] = useState(false);

  const [errorResponse, setErrorResponse] = useState();
  const [resetEmailStatus, setResetEmailStatus] = useState({});

  const forgotPassword = async (email) => {
    setPswResetMailSending(true);
    try {
      const result = await resetPasswordRequest(email);
      setPswResetMailSending(false);
      result['Status '] === ' success' &&
        setResetEmailStatus({ email, status: true, message: '' });
    } catch (error) {
      console.log('reset email ', error);
      setPswResetMailSending(false);
      setResetEmailStatus({
        status: false,
        message: error?.message ? error.message : 'Network Error!',
      });
      setTimeout(() => {
        setResetEmailStatus({});
      }, 5000);
    }
  };

  const loginUser = async (data) => {
    setIsLoading(true);
    try {
      const result = await login(data);
      if (result.roleId !== 3) {
        setErrorResponse('Please use counsellor Id to login. ');
        updateLoginStatus(false);
        setIsLoading(false);
        setTimeout(() => {
          setErrorResponse('');
        }, 5000);
        return;
      }

      updateLoginStatus(true);
      localStorage.setItem('logintoken', result.token);
      localStorage.setItem('userinfo', JSON.stringify(result));
      const time = Math.round(new Date().getTime() / 1000);
      localStorage.setItem('loginTime', time + 3300);
      localStorage.setItem('tokenRefreshTime', time + 1800);
      setErrorResponse('');
      sessionTimer();
      history.push('home');
    } catch (error) {
      setIsLoading(false);
      setErrorResponse(error?.message || 'Network Error');
      updateLoginStatus(false);
      console.log('userLogin', error);
      setTimeout(() => {
        setErrorResponse('');
      }, 10000);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('logintoken')) {
      history.push('home');
      return;
    }
    history.listen((location, action) => {
      if (location.pathname === '/login') {
        setResetEmailStatus({ status: false, message: '' });
        console.log('histry.listen userlogin page');
        return;
      }
    });
  }, []);
  if (localStorage.getItem('userinfo')) {
    return <></>;
  }
  return (
    <>
      <div className="pico-contentWrapper">
        <div className="pico-container flexcontainer">
          <LoginImageComp />
          <div className="pico-formwrapper">
            {resetEmailStatus.status ? (
              <ForgotPasswordComp email={resetEmailStatus.email} />
            ) : (
              <LoginComp
                title1={location.title1 || 'Log In'}
                title2={location.title2}
                loginFunction={loginUser}
                errorResponse={errorResponse}
                forgotPassword={forgotPassword}
                resetEmailStatus={resetEmailStatus}
                isLoading={isLoading}
                pswResetMailSending={pswResetMailSending}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLogin;
