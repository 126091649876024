import React from 'react';

function InpersonIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3891 5.77783H16.6113V7.22228H22.3891V22.3889H16.6113V23.8334H23.8336V7.22228C23.8336 6.83919 23.6814 6.47179 23.4105 6.2009C23.1396 5.93001 22.7722 5.77783 22.3891 5.77783Z"
        fill="#6D6D6D"
      />
      <path
        d="M14.3576 2.1665H4.41978C4.01371 2.1665 3.62426 2.32782 3.33712 2.61496C3.04998 2.90209 2.88867 3.29154 2.88867 3.69761V23.8332H15.8887V3.69761C15.8887 3.29154 15.7274 2.90209 15.4402 2.61496C15.1531 2.32782 14.7636 2.1665 14.3576 2.1665ZM14.4442 22.3887H12.2776V20.2221H6.49978V22.3887H4.33312V3.69761C4.33312 3.68623 4.33536 3.67496 4.33971 3.66445C4.34407 3.65393 4.35045 3.64438 4.3585 3.63633C4.36655 3.62828 4.3761 3.6219 4.38662 3.61755C4.39713 3.61319 4.4084 3.61095 4.41978 3.61095H14.3576C14.3689 3.61095 14.3802 3.61319 14.3907 3.61755C14.4012 3.6219 14.4108 3.62828 14.4188 3.63633C14.4269 3.64438 14.4333 3.65393 14.4376 3.66445C14.442 3.67496 14.4442 3.68623 14.4442 3.69761V22.3887Z"
        fill="#6D6D6D"
      />
      <path
        d="M5.7793 5.77783H7.22374V7.22228H5.7793V5.77783Z"
        fill="#6D6D6D"
      />
      <path
        d="M8.66602 5.77783H10.1105V7.22228H8.66602V5.77783Z"
        fill="#6D6D6D"
      />
      <path
        d="M11.5547 5.77783H12.9991V7.22228H11.5547V5.77783Z"
        fill="#6D6D6D"
      />
      <path
        d="M5.7793 9.38867H7.22374V10.8331H5.7793V9.38867Z"
        fill="#6D6D6D"
      />
      <path
        d="M8.66602 9.38867H10.1105V10.8331H8.66602V9.38867Z"
        fill="#6D6D6D"
      />
      <path
        d="M11.5547 9.38867H12.9991V10.8331H11.5547V9.38867Z"
        fill="#6D6D6D"
      />
      <path d="M5.7793 13H7.22374V14.4444H5.7793V13Z" fill="#6D6D6D" />
      <path d="M8.66602 13H10.1105V14.4444H8.66602V13Z" fill="#6D6D6D" />
      <path d="M11.5547 13H12.9991V14.4444H11.5547V13Z" fill="#6D6D6D" />
      <path
        d="M5.7793 16.6113H7.22374V18.0558H5.7793V16.6113Z"
        fill="#6D6D6D"
      />
      <path
        d="M8.66602 16.6113H10.1105V18.0558H8.66602V16.6113Z"
        fill="#6D6D6D"
      />
      <path
        d="M11.5547 16.6113H12.9991V18.0558H11.5547V16.6113Z"
        fill="#6D6D6D"
      />
      <path
        d="M16.6113 9.38867H18.0558V10.8331H16.6113V9.38867Z"
        fill="#6D6D6D"
      />
      <path d="M19.5 9.38867H20.9444V10.8331H19.5V9.38867Z" fill="#6D6D6D" />
      <path d="M16.6113 13H18.0558V14.4444H16.6113V13Z" fill="#6D6D6D" />
      <path d="M19.5 13H20.9444V14.4444H19.5V13Z" fill="#6D6D6D" />
      <path
        d="M16.6113 16.6113H18.0558V18.0558H16.6113V16.6113Z"
        fill="#6D6D6D"
      />
      <path d="M19.5 16.6113H20.9444V18.0558H19.5V16.6113Z" fill="#6D6D6D" />
    </svg>
  );
}

export default InpersonIcon;
