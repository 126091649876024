import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  format,
  subDays,
  addDays,
  compareAsc,
  differenceInMinutes,
} from 'date-fns';

import CloseIcon from '../../components/svg/closeIcon';
import LeftArrow from '../../components/svg/leftArrow';
import RightArrow from '../../components/svg/rightArrow';
import { createCounsellorAvailableSlots, getAvailableSlot } from '../../modals';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    padding: '30px',
    borderRadius: '25px',
    maxWidth: '93%',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10',
  },
};
Modal.setAppElement(document.getElementById('root'));

function CalendarModal({
  show,
  showHideModal,
  editable = false,
  navigationAllowed = false,
  firstTime = false,
  viewOnly = false,
  skipWeeks = false,
}) {
  const USER_INFO = { ...JSON.parse(localStorage.getItem('userinfo')) };
  const [headerMonths, setHeaderMonths] = useState({});
  const { nextMonth, prevMonth, nextDate, prevDate } = headerMonths;
  const [meetingsArray, setMeetingsArray] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [navigationState, setNavigationState] = useState({});
  const { prevClicked = true, nextClicked = false } = navigationState;
  const [calendarStartDate, setCalendarStartDate] = useState('');
  const { calStartDate, calStartFlag } = calendarStartDate;
  const [currentWeekRange, setCurrentWeekRange] = useState({});
  const { currentStart, currentEnd } = currentWeekRange;
  const [loadedDates, setLoadedDates] = useState([]);
  const [localCaId, setLocalCaId] = useState(1);
  const calendarRef = useRef();
  const [apiResponse, setApiResponse] = useState('');
  const [saving, setSaving] = useState(false);
  const renderHeaderDates = (args) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{format(args.date, 'EEEE')}</span>
        <span>{format(args.date, 'd')}</span>
      </div>
    );
  };
  const removeTempEvents = () => {
    const calendarApi = calendarRef.current.getApi();
    if (calendarApi.getEventById('new')) {
      calendarApi.getEventById('new').remove();
    }
    if (calendarApi.getEventById('new')) {
      removeTempEvents();
    }
  };
  const createMeetingArray = (data, reset = false) => {
    let meetingarr = [];
    if (reset) {
      setLoadedDates([currentStart]);
    } else {
      meetingarr = [...meetingsArray];
    }
    if (data.length > 0) {
      data.map((item) => {
        const { start, end, date, caId, title } = item;
        meetingarr.push({
          start: `${date}T${start}`,
          end: `${date}T${end}`,
          id: caId,
          title,
        });
      });
    }
    setMeetingsArray(meetingarr);
    reset && removeTempEvents();
  };
  const handleDateSelect = (selectInfo) => {
    let date = format(selectInfo.start, 'yyyy-MM-dd');
    let startDay = format(selectInfo.start, 'EEEE');
    let endDay = format(selectInfo.end, 'EEEE');
    let calendarApi = selectInfo.view.calendar;
    if (startDay !== endDay) {
      calendarApi.unselect();
      alert("Can't select more than one day");
      return false;
    }
    if (compareAsc(selectInfo.start, new Date()) === -1) {
      calendarApi.unselect();
      alert("Can't select past days or past hours");
      return false;
    }

    // let title = prompt('Please enter a new title for your event');
    calendarApi.unselect(); // clear date selection
    let localcaid = localCaId;
    calendarApi.addEvent({
      localCaId: localCaId,
      id: 'new',
      title: 'Available',
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay,
    });
    let data = [
      ...meetingData,
      {
        localCaId: localCaId,
        caId: null,
        collegeId: USER_INFO.schoolId,
        counselorId: USER_INFO.id,
        availableDate: date,
        startTime: format(selectInfo.start, 'HH:mm:ss'),
        endTime: format(selectInfo.end, 'HH:mm:ss'),
      },
    ];
    setLocalCaId(localcaid + 1);
    setMeetingData(data);
  };
  const renderEventContent = ({ event: { start, end, title } }) => {
    const difference = differenceInMinutes(end, start);
    const starttime = format(start, 'hh:mm a');
    const endtime = format(end, 'hh:mm a');
    return (
      <>
        <p style={{ fontWeight: '400', fontSize: '12px' }}>
          {title}
          <span style={{ fontWeight: '400', fontSize: '10px' }}>
            {difference <= 30 && ' ' + starttime + ' - ' + endtime}
          </span>
        </p>
        {difference > 30 && (
          <p style={{ fontWeight: '400', fontSize: '12px' }}>
            {starttime + ' - ' + endtime}
          </p>
        )}
      </>
    );
  };
  const calendarNavigation = (direction) => {
    const calendarApi = calendarRef.current.getApi();
    direction === 'prev' && calendarApi.prev();
    direction === 'next' && calendarApi.next();
    const prevMonth = format(calendarApi.getDate(), 'MMMM');
    const nextMonth = format(addDays(calendarApi.getDate(), 6), 'MMMM');
    const prevDate = format(calendarApi.getDate(), 'dd');
    const nextDate = format(addDays(calendarApi.getDate(), 6), 'dd');
    setHeaderMonths({ nextMonth, prevMonth, prevDate, nextDate });
    if (calStartDate === format(calendarApi.getDate(), 'yyyy-MM-dd')) {
      setCalendarStartDate({ ...calendarStartDate, calStartFlag: true });
    } else {
      setCalendarStartDate({ ...calendarStartDate, calStartFlag: false });
    }
    const from = format(calendarApi.getDate(), 'yyyy-MM-dd');
    const to = format(addDays(calendarApi.getDate(), 6), 'yyyy-MM-dd');
    setCurrentWeekRange({
      currentStart: from,
      currentEnd: to,
    });
    if (!loadedDates.includes(from)) {
      const loadedates = [...loadedDates];
      loadedates.push(from);
      setLoadedDates(loadedates);
      loadAvailableSlot({
        collegeId: USER_INFO.schoolId,
        counselorId: USER_INFO.id,
        fromDate: from,
        toDate: to,
      });
    }
  };
  const loadAvailableSlot = async (data, reset = false) => {
    try {
      const result = await getAvailableSlot(data);
      const mdata = [];
      result.map(({ date, availableSlot }) => {
        let formateddate = format(new Date(date), 'yyyy-MM-dd');
        availableSlot.map(({ startTime, endTime, caId }) => {
          mdata.push({
            title: 'Available',
            start: startTime.split(':')[0] + ':' + startTime.split(':')[1],
            end: endTime.split(':')[0] + ':' + endTime.split(':')[1],
            date: formateddate,
            caId,
          });
        });
      });

      createMeetingArray(mdata, reset);
    } catch (error) {
      console.log('get available slots', error);
    }
  };
  const createSlot = async () => {
    setSaving(true);
    try {
      let data = [];
      meetingData.map((item) => {
        delete item.localCaId;
        data.push(item);
      });

      await createCounsellorAvailableSlots(meetingData);
      setSaving(false);
      setApiResponse('Availability set successfully!!!');
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
      setMeetingData([]);
      loadAvailableSlot(
        {
          collegeId: USER_INFO.schoolId,
          counselorId: USER_INFO.id,
          fromDate: currentStart,
          toDate: currentEnd,
        },
        true
      );
    } catch (error) {
      setSaving(false);
      setApiResponse(error?.message || 'Something went wrong!');
      console.log('create slot', error);
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    }
  };
  const editMeeting = ({
    event: {
      start,
      end,
      id,
      extendedProps: { localCaId },
    },
  }) => {
    let data = [...meetingData];
    if (id === 'new') {
      const index = meetingData.findIndex(
        (i) => parseInt(i.localCaId) === parseInt(localCaId)
      );
      if (index > -1) {
        data.splice(index, 1);
      }
    } else {
      const index = meetingData.findIndex(
        (i) => parseInt(i.caId) === parseInt(id)
      );
      if (index > -1) {
        data.splice(index, 1);
      }
    }
    data.push({
      localCaId: localCaId || null,
      caId: id === 'new' ? null : id,
      collegeId: USER_INFO.schoolId,
      counselorId: USER_INFO.id,
      availableDate: format(start, 'yyyy-MM-dd'),
      startTime: format(start, 'HH:mm:ss'),
      endTime: format(end, 'HH:mm:ss'),
    });
    setMeetingData(data);
    console.log(data);
  };

  const preventSlotBeforeStartTime = ({ start, end }) => {
    const slotmax = new Date(format(start, 'yyyy-MM-dd'));
    const slotmin = 6;
    slotmax.setHours(23);
    slotmax.setMinutes(0);
    if (parseInt(format(start, 'H')) < slotmin) return false;
    if (
      compareAsc(end, slotmax) === 1 ||
      parseInt(format(start, 'H')) > parseInt(format(end, 'H'))
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    const todaysWeek = new Date().getDay();
    const difference = 7 - todaysWeek;
    let calendarStartDate;
    if (skipWeeks) {
      calendarStartDate = addDays(new Date(), difference + 7);
    } else {
      calendarStartDate = subDays(new Date(), todaysWeek);
    }
    setCalendarStartDate({
      calStartDate: format(calendarStartDate, 'yyyy-MM-dd'),
      calStartFlag: true,
    });

    const nextMonth = format(addDays(calendarStartDate, 6), 'MMMM');
    const nextDate = format(addDays(calendarStartDate, 6), 'dd');
    const prevMonth = format(calendarStartDate, 'MMMM');
    const prevDate = format(calendarStartDate, 'dd');
    setHeaderMonths({ nextMonth, prevMonth, nextDate, prevDate });
    const from = format(calendarStartDate, 'yyyy-MM-dd');
    const to = format(addDays(calendarStartDate, 6), 'yyyy-MM-dd');
    setCurrentWeekRange({
      currentStart: from,
      currentEnd: to,
    });
    setLoadedDates([from]);
    !firstTime &&
      loadAvailableSlot({
        collegeId: USER_INFO.schoolId,
        counselorId: USER_INFO.id,
        fromDate: from,
        toDate: to,
      });
    setTimeout(() => {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(calendarStartDate);
      let timezonediv = document.getElementsByClassName(
        'fc-timegrid-axis-frame'
      )[0];
      timezonediv.innerHTML += '<span class="timezone-calendar">PDT<span>';
    }, 10);
  }, []);
  return (
    <div>
      <Modal
        // ariaHideApp={false}
        isOpen={show}
        // onAfterOpen={}
        onRequestClose={() => {
          showHideModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="popheader SchedulecalanderModal">
          <h3>
            {`${
              viewOnly ? 'View ' : `${skipWeeks ? 'Set upcoming ' : 'Set '}`
            } availability`}
          </h3>
          <div className="calendarModal-subHeader">
            {firstTime ? (
              <p>
                Set your availability for the next 2 weeks. You can adjust
                availability in the settings page.
              </p>
            ) : null}
            {navigationAllowed && (
              <div className="monthSelector">
                {(prevClicked && !skipWeeks) || calStartFlag ? (
                  ''
                ) : (
                  <span
                    onClick={() => {
                      calendarNavigation('prev');
                      setNavigationState({
                        prevClicked: true,
                        nextClicked: false,
                      });
                    }}
                  >
                    <LeftArrow />
                  </span>
                )}
                {`${prevMonth + `${prevDate} - ${nextMonth}` + nextDate}`}

                {nextClicked && !skipWeeks ? (
                  ''
                ) : (
                  <span
                    onClick={() => {
                      calendarNavigation('next');
                      setNavigationState({
                        prevClicked: false,
                        nextClicked: true,
                      });
                    }}
                  >
                    <RightArrow />
                  </span>
                )}
              </div>
            )}
          </div>
          {/* <div>
            <span>
              <input type="checkbox" />
            </span>
            Duplicate schedule for next week
          </div>*/}

          <span className="popheaderspan">
            <button
              className="transpBtn"
              onClick={() => {
                showHideModal(false);
              }}
            >
              {<CloseIcon />}
            </button>
          </span>
        </div>
        <div class="content">
          <div className="calanderboxWrapper popcalendar">
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              eventBackgroundColor="#EAF5FF"
              eventBorderColor="transparent"
              eventTextColor="#1367B2"
              slotMinTime="06:00:00"
              slotMaxTime="23:00:00"
              initialView="timeGridWeek"
              editable={editable}
              selectable={!viewOnly}
              dayMaxEvents={false}
              allDaySlot={false}
              headerToolbar={false}
              dayHeaderFormat={{
                // day: 'numeric',
                weekday: 'long',
                // omitCommas: true,
              }}
              dayHeaderContent={renderHeaderDates}
              selectOverlap={false}
              eventOverlap={false}
              weekends={true}
              height="auto"
              events={meetingsArray}
              select={handleDateSelect}
              eventContent={renderEventContent}
              // eventMaxStack={1}
              // slotEventOverlap={false}
              // eventClick={handleEventClick}
              // eventsSet={handleEvents}
              eventResize={editMeeting}
              eventDrop={editMeeting}
              eventResizableFromStart={true}
              // firstDay={1} start from monday
              // validRange={{ start: '2021-08-18' }}
              eventAllow={preventSlotBeforeStartTime}
            />
          </div>
        </div>
        <div className="popfooter">
          <span className="err">{apiResponse}</span>
          {!viewOnly && (
            <button
              onClick={() => {
                showHideModal(false);
              }}
              className="btn cancelbtn"
            >
              Cancel
            </button>
          )}
          {!viewOnly && (
            <button
              disabled={meetingData.length === 0 || saving}
              onClick={() => {
                createSlot();
              }}
              className={`btn ${
                meetingData.length === 0 || saving
                  ? ' disabled-roundedbtn'
                  : 'blueroundedbtn'
              }`}
            >
              Save
            </button>
          )}
          {viewOnly && (
            <button
              onClick={() => {
                showHideModal(false);
              }}
              className="btn blueroundedbtn"
            >
              Close
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CalendarModal;
