import React from 'react';

function TextAreaIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.52148 16.2926C2.52148 16.1275 2.58707 15.9692 2.70381 15.8524C2.82055 15.7357 2.97889 15.6701 3.14398 15.6701H11.859C12.0241 15.6701 12.1824 15.7357 12.2992 15.8524C12.4159 15.9692 12.4815 16.1275 12.4815 16.2926C12.4815 16.4577 12.4159 16.616 12.2992 16.7328C12.1824 16.8495 12.0241 16.9151 11.859 16.9151H3.14398C2.97889 16.9151 2.82055 16.8495 2.70381 16.7328C2.58707 16.616 2.52148 16.4577 2.52148 16.2926ZM2.52148 12.5576C2.52148 12.3925 2.58707 12.2342 2.70381 12.1174C2.82055 12.0007 2.97889 11.9351 3.14398 11.9351H16.839C17.0041 11.9351 17.1624 12.0007 17.2792 12.1174C17.3959 12.2342 17.4615 12.3925 17.4615 12.5576C17.4615 12.7227 17.3959 12.881 17.2792 12.9978C17.1624 13.1145 17.0041 13.1801 16.839 13.1801H3.14398C2.97889 13.1801 2.82055 13.1145 2.70381 12.9978C2.58707 12.881 2.52148 12.7227 2.52148 12.5576ZM2.52148 8.82259C2.52148 8.65749 2.58707 8.49916 2.70381 8.38241C2.82055 8.26567 2.97889 8.20009 3.14398 8.20009H16.839C17.0041 8.20009 17.1624 8.26567 17.2792 8.38241C17.3959 8.49916 17.4615 8.65749 17.4615 8.82259C17.4615 8.98769 17.3959 9.14602 17.2792 9.26276C17.1624 9.3795 17.0041 9.44509 16.839 9.44509H3.14398C2.97889 9.44509 2.82055 9.3795 2.70381 9.26276C2.58707 9.14602 2.52148 8.98769 2.52148 8.82259ZM7.50148 5.08759C7.50148 4.92249 7.56707 4.76416 7.68381 4.64741C7.80055 4.53067 7.95889 4.46509 8.12398 4.46509H16.839C17.0041 4.46509 17.1624 4.53067 17.2792 4.64741C17.3959 4.76416 17.4615 4.92249 17.4615 5.08759C17.4615 5.25269 17.3959 5.41102 17.2792 5.52776C17.1624 5.6445 17.0041 5.71009 16.839 5.71009H8.12398C7.95889 5.71009 7.80055 5.6445 7.68381 5.52776C7.56707 5.41102 7.50148 5.25269 7.50148 5.08759Z"
        fill="#999999"
      />
    </svg>
  );
}

export default TextAreaIcon;
