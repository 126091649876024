import React from 'react';

function WebCamIcon({ width = 20, height = 21 }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7755 6.16076L15.2855 7.59445V4.63758C15.2855 3.95088 14.7272 3.39258 14.0405 3.39258H2.5243C1.8376 3.39258 1.2793 3.95088 1.2793 4.63758V15.8426C1.2793 16.5293 1.8376 17.0876 2.5243 17.0876H14.0405C14.7272 17.0876 15.2855 16.5293 15.2855 15.8426V12.8857L17.7755 14.3194C18.1899 14.5587 18.7093 14.2591 18.7093 13.7825V6.69961C18.7093 6.22106 18.1899 5.92148 17.7755 6.16076ZM13.8849 15.687H2.67992V4.7932H13.8849V15.687ZM17.3087 12.4383L15.2855 11.275V9.20712L17.3087 8.04187V12.4383ZM4.08055 7.2832H6.2593C6.34489 7.2832 6.41492 7.21317 6.41492 7.12758V6.19383C6.41492 6.10823 6.34489 6.0382 6.2593 6.0382H4.08055C3.99495 6.0382 3.92492 6.10823 3.92492 6.19383V7.12758C3.92492 7.21317 3.99495 7.2832 4.08055 7.2832Z"
        fill="#999999"
      />
    </svg>
  );
}

export default WebCamIcon;
