import { Request } from '../utils/request';

export const createCounsellorAvailability = async (data) => {
  const result = await Request.post('scheduler/counselor-availability', data);
  return result;
};
export const getDefaultMeetingSettings = async () => {
  const result = await Request.get('scheduler/counselor/meeting-detail');
  return result;
};
export const createDefaultMeetingSettings = async (data) => {
  const result = await Request.post('scheduler/counselor/meeting-detail', data);
  return result;
};
export const updateDefaultMeetingSettings = async (data) => {
  const result = await Request.put('scheduler/counselor/meeting-detail', data);
  return result;
};

export const googleSSO = async () => {
  const result = await Request.get('scheduler/forwardGoogleSSO');
  return result;
};
export const saveGoogleAuthToken = async (code) => {
  const result = await Request.get(`scheduler/counselor/callback?code=${code}`);
  return result;
};
export const counsellorType = async () => {
  const result = await Request.get('scheduler/counselor/counselor-type');
  return result;
};
export const getAvailableSlot = async (data) => {
  const result = await Request.get(`scheduler/counselor-availability`, data);
  return result;
};
export const createCounsellorAvailableSlots = async (data) => {
  const result = Request.post('scheduler/counselor/availability-slots', data);
  return result;
};
export const getMeeetingDetails = async (data) => {
  const result = Request.get('scheduler/counselor-meeting-details', data);
  return result;
};
export const getStudentMeetingsById = async (cId, sId) => {
  const result = await Request.get(
    `/scheduler/counselor/${cId}/students/${sId}`
  );
  return result;
};
export const editStudentMeetings = async (data) => {
  const result = await Request.put('scheduler/appointment-notes', data);
  return result;
};
