import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Passeye from './svg/passeye';
import PasseyeCrossed from './svg/passeyeCrossed';

function LoginComp({
  title1,
  title2,
  loginFunction,
  errorResponse,
  forgotPassword,
  resetEmailStatus,
  isLoading,
  pswResetMailSending,
}) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [pswVisible, setPswVisible] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    trigger,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const emailId = useRef({});
  emailId.current = watch('email', '');

  const submit = handleSubmit(({ email, password }) => {
    loginFunction({ emailId: email, password });
  });
  return (
    <div className="pico-formwrapper">
      <div className="formPico">
        <h3 className={title2 ? 'h3btmpad' : ''}>{title1}</h3>
        {title2 && <h5>{title2}</h5>}

        <form
          action="#"
          className="signin-form"
          onSubmit={() => {
            submit();
          }}
        >
          <div className="form-group mb-3">
            <label className="label" for="name">
              Email
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              {...register('email', {
                required: 'Enter your email',
                pattern: {
                  value: emailRegex,
                  message: 'invalid email address',
                },
              })}
            />
            {errors.email && <p className="err">{errors.email.message}</p>}
          </div>

          <div className="form-group mb-3">
            <label className="label" for="password">
              Password
            </label>
            <div className="inputwithappendicon">
              <div className="inputfieldwrap">
                <input
                  type={pswVisible ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Password"
                  {...register('password', {
                    required: 'Password Required',
                  })}
                />
              </div>
              <div
                className="eyeIconSpan"
                onClick={() => {
                  setPswVisible(!pswVisible);
                }}
              >
                {!pswVisible ? <PasseyeCrossed /> : <Passeye />}
              </div>
            </div>
          </div>

          <div className="d-flex mb-5 align-items-center">
            {(pswResetMailSending ||
              errors.password ||
              errorResponse ||
              resetEmailStatus?.message) && (
              <span className={pswResetMailSending ? 'notifi-msg' : 'err'}>
                {(pswResetMailSending &&
                  'Sending email for password reset...') ||
                  errors.password?.message ||
                  errorResponse ||
                  resetEmailStatus?.message}
              </span>
            )}

            <span
              className="ml-auto"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                if (pswResetMailSending) {
                  return;
                }
                trigger('email');
                if (emailId.current && emailRegex.test(emailId.current)) {
                  forgotPassword(emailId.current);
                } else {
                  setFocus('email');
                }
              }}
            >
              <a className="forgot-pass">Forgot Password</a>
            </span>
          </div>

          {/* <label className="control control--checkbox mb-0">
            <span className="caption">Remember me</span>
            <input type="checkbox" checked="" />
            <div className="control__indicator">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="3.5"
                  fill="white"
                  stroke="#CCCCCC"
                />
              </svg>
            </div>
          </label> */}

          <div className="form-group">
            <button
              type="submit"
              className="form-control btn btn-primary submit px-3"
              onClick={() => {
                submit();
              }}
              disabled={isLoading}
            >
              {!isLoading ? 'Log in' : 'Logging in...'}
            </button>
          </div>

          {/* <div className="form-group d-md-flex newtosignup">
            <div className="w-50 text-center">
              New to pico?{' '}
              <a href="#" className="forgot-pass">
                Sign up
              </a>
            </div>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default LoginComp;
