import React from 'react';

function ClockIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1603 1.54468C5.34761 1.54468 1.44531 5.44697 1.44531 10.2597C1.44531 15.0724 5.34761 18.9747 10.1603 18.9747C14.973 18.9747 18.8753 15.0724 18.8753 10.2597C18.8753 5.44697 14.973 1.54468 10.1603 1.54468ZM10.1603 17.4962C6.16464 17.4962 2.92375 14.2553 2.92375 10.2597C2.92375 6.26401 6.16464 3.02312 10.1603 3.02312C14.156 3.02312 17.3969 6.26401 17.3969 10.2597C17.3969 14.2553 14.156 17.4962 10.1603 17.4962Z"
        fill="#999999"
      />
      <path
        d="M13.5581 12.7225L10.7841 10.7168V5.90221C10.7841 5.81661 10.714 5.74658 10.6284 5.74658H9.69273C9.60714 5.74658 9.53711 5.81661 9.53711 5.90221V11.2596C9.53711 11.3102 9.56045 11.3568 9.6013 11.386L12.8188 13.7321C12.8889 13.7826 12.9861 13.7671 13.0367 13.699L13.5931 12.9403C13.6437 12.8683 13.6281 12.7711 13.5581 12.7225Z"
        fill="#999999"
      />
    </svg>
  );
}

export default ClockIcon;
