import React, { useEffect, useRef, useState } from 'react';
import '../css/student.css';
import { getAcademicStandings, getStudentTaskList } from '../modals/counsellor';
import PhoneReceiverGray from '../components/svg/phoneReceiverGray';
import EmailGray from '../components/svg/emailGray';
import BackArrow from '../components/svg/backArrow';
import StudentOverview from './studentOverview';
import StudentClasses from './studentClasses';
import StudentContactModal from './modal/studentContactModal';
import SearchIcon from '../components/svg/searchIcon';
import StudentDocument from './studentDocument';
import { generatePdf, getGoalProgress, getStudentFiles } from '../modals';
import StudentMeetings from './studentMeetings';
import { getClassesLeft, getClassesTaken } from '../modals';
import { useHistory } from 'react-router';

function StudentView({
  updateStudentSelected,
  updateStudentList,
  studentSelectedInfo,
  studentSelectedInfo: {
    academicStandings,
    phoneNumber,
    goal,
    userFullName,
    financialAidFlag,
    userId,
    communityCollegeName,
    email,
    tags,
    disabilityFlag,
    ethnicity,
    collegeStudentId,
    gender,
    majorName,
    goalId,
  },
}) {
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState('overview');
  const [studentTaskList, setStudentTaskList] = useState([]);
  const [academicStandingList, setAcademicStandingList] = useState([]);
  const [goalProgress, setGoalProgress] = useState({});

  const [studentDocuments, setStudentDocuments] = useState([]);
  const [studentDocumentsInitial, setStudentDocumentsInitial] = useState([]);
  const [docIsLoading, setDocIsLoading] = useState(true);

  const [showContactModal, setShowContactModal] = useState(false);

  const [classesData, setClassesData] = useState([]);
  const [sortedBy, setSortedBy] = useState({
    sortedField: 'className',
    order: 'ascending',
  });
  const { sortedField, order } = sortedBy;
  const [classDataInitial, setClassDataInitial] = useState();
  const [serachTextClasses, setSerachTextClasses] = useState('');

  const [serachTextDoc, setSerachTextDoc] = useState('');
  const [degreeAuditInfo, setDegreeAuditInfo] = useState({
    loading: 'Loading degree audit',
    url: '',
    error: '',
  });

  let classesTaken = [];
  let classesLeft = [];

  const classesSorting = (fieldname, ordertype, filteredData = '') => {
    console.log(fieldname, ordertype);
    const numberfield = ['units', 'year'];
    let data = filteredData
      ? filteredData
      : serachTextClasses
      ? [...classesData]
      : [...classDataInitial];
    if (ordertype === 'ascending') {
      if (!numberfield.includes(fieldname)) {
        data.sort((a, b) =>
          a[fieldname]?.toString()?.localeCompare(b[fieldname]?.toString())
        );
      } else {
        data.sort((a, b) => a[fieldname] - b[fieldname]);
      }
    }
    if (ordertype === 'descending') {
      if (!numberfield.includes(fieldname)) {
        data.sort((b, a) =>
          a[fieldname]?.toString()?.localeCompare(b[fieldname]?.toString())
        );
      } else {
        data.sort((b, a) => a[fieldname] - b[fieldname]);
      }
    }
    setSortedBy({ sortedField: fieldname, order: ordertype });
    setClassesData(data);
  };

  const classfilter = (value) => {
    if (value !== '') {
      if (value.trim() !== '') {
        let data = [...classDataInitial];

        const classesSerachPattern = new RegExp(
          '(\\w*' + value + '\\w*)',
          'gi'
        );
        let filteredData = data.filter((item) => {
          return item.className.match(classesSerachPattern);
        });
        classesSorting(sortedField, order, filteredData);
      } else {
        classesSorting(sortedField, order, classDataInitial);
      }
    } else {
      classesSorting(sortedField, order, classDataInitial);
    }
  };
  const docFilter = (value, docs = '') => {
    if (value !== '') {
      if (value.trim() !== '') {
        let data = docs ? [...docs] : [...studentDocumentsInitial];
        const fileSerachPattern = new RegExp('(\\w*' + value + '\\w*)', 'gi');
        let filteredData = data.filter((item) => {
          return item.fileName.match(fileSerachPattern);
        });
        setStudentDocuments(filteredData);
      } else {
        setStudentDocuments(studentDocumentsInitial);
      }
    } else {
      setStudentDocuments(studentDocumentsInitial);
    }
  };

  const showHideContactModal = (bool) => {
    setShowContactModal(bool);
  };

  const loadAcademicStandings = async () => {
    try {
      const result = await getAcademicStandings();
      setAcademicStandingList(result);
    } catch (error) {
      console.log('academic stanging list', error);
    }
  };

  const loadStudentTaskList = async (data) => {
    try {
      const result = await getStudentTaskList(data);
      setStudentTaskList(result);
    } catch (error) {
      console.log('tasklist', error);
    }
  };
  const loadStudentDocuments = async (data) => {
    setDocIsLoading(true);
    try {
      const result = await getStudentFiles(data);
      setStudentDocumentsInitial(result);
      setStudentDocuments(result);
      serachTextDoc && docFilter(serachTextDoc, result);
      setDocIsLoading(false);
    } catch (error) {
      console.log('student documents', error);
      setDocIsLoading(false);
    }
  };

  const loadStudentClassesTaken = async (data) => {
    try {
      const result = await getClassesTaken(data);
      classesTaken = result;
      const finalResult = [...result, ...classesLeft];
      finalResult.sort((a, b) => a.className?.localeCompare(b.className));
      setClassDataInitial(finalResult);
      setClassesData(finalResult);
    } catch (error) {
      console.log('classes taken', error);
    }
  };
  const loadStudentClassesLeft = async (data) => {
    try {
      const result = await getClassesLeft(data);
      classesLeft = result;
      const finalResult = [...result, ...classesTaken];
      finalResult.sort((a, b) => a.className?.localeCompare(b.className));
      setClassDataInitial(finalResult);
      setClassesData(finalResult);
    } catch (error) {
      console.log('classes left', error);
    }
  };
  const loadGoalProgess = async (data) => {
    try {
      const result = await getGoalProgress(data);
      setGoalProgress(result);
    } catch (error) {
      console.log('goal progress', error);
    }
  };
  const loadPdf = async (data) => {
    try {
      const {
        aaAsGeUrl = '',
        csuGeUrl = '',
        igetcUrl = '',
      } = await generatePdf(data);
      setDegreeAuditInfo({
        loading: '',
        url: aaAsGeUrl || csuGeUrl || igetcUrl,
        error: '',
      });
    } catch (error) {
      setDegreeAuditInfo({
        loading: '',
        url: '',
        error: 'No degree audit',
      });
      console.log('generate pdf', error);
    }
  };
  useEffect(() => {
    loadGoalProgess({ sagId: goalId, studentId: userId });
    loadPdf({ sagId: goalId, studentId: userId, acGoalId: goalId });
    loadStudentTaskList({ email });
    loadAcademicStandings();
    loadStudentClassesTaken({ studentId: userId });
    loadStudentClassesLeft({ sagId: goalId, studentId: userId });
  }, []);

  const BACK_LINK = localStorage.getItem('backLink_pico');
  const BACK_LINK_TITLE = localStorage.getItem('backlink_title_pico');

  return (
    <>
      <div className="pico-container overviewList">
        <div className="studenviewWrapper">
          <div className="studentViewContent">
            <div className="profiledettailcard">
              <div className="profile-image-container">
                <img src="images/pico-loginImage.png" alt="" />
              </div>
              <h3>{userFullName}</h3>
              <h5>{collegeStudentId || 'Not registered'}</h5>
              <ul className="contactIcons">
                <li>
                  <span
                    onClick={() => {
                      showHideContactModal(true);
                    }}
                  >
                    <PhoneReceiverGray />
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      showHideContactModal(true);
                    }}
                  >
                    <EmailGray />
                  </span>
                </li>
              </ul>

              <ul className="profiledatas">
                <li>
                  <h6>{majorName || '--'}</h6>
                  {/* <p>Class of 2021</p> */}
                </li>

                <li className="twocol txt-left">
                  <p>Ethnicity</p>
                  <h6>{ethnicity || '--'}</h6>
                </li>

                <li className="twocol txt-left">
                  <p>Gender</p>
                  <h6>{gender || '--'}</h6>
                </li>

                <li className="txt-left">
                  <p>Disability Services </p>
                  <h6>{disabilityFlag ? 'Yes' : 'No'}</h6>
                </li>
              </ul>
            </div>

            <div
              className={
                tabSelected === 'classes' || tabSelected === 'documents'
                  ? 'rightContentCard studentclasses'
                  : 'rightContentCard'
              }
            >
              <div className="topBreadcrumbSection">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (BACK_LINK) {
                      localStorage.removeItem('backLink_pico');
                      localStorage.removeItem('backlink_title_pico');
                      history.push(BACK_LINK);
                      return;
                    }
                    updateStudentSelected(false);
                  }}
                >
                  <span>
                    <BackArrow />
                  </span>{' '}
                  Go back to {BACK_LINK_TITLE || 'All Students'}
                </a>
                {(tabSelected === 'classes' || tabSelected === 'documents') && (
                  <div className="topSearch">
                    <div
                      className={
                        tabSelected === 'classes'
                          ? serachTextClasses
                            ? 'searchbox search-active'
                            : 'searchbox'
                          : tabSelected === 'documents'
                          ? serachTextDoc
                            ? 'searchbox search-active'
                            : 'searchbox'
                          : 'searchbox'
                      }
                    >
                      <span className="searchform">
                        <input
                          type="text"
                          placeholder={
                            tabSelected === 'classes'
                              ? 'Search classes'
                              : tabSelected === 'documents' &&
                                'Search documents'
                          }
                          name="search"
                          value={
                            tabSelected === 'classes'
                              ? serachTextClasses
                              : tabSelected === 'documents'
                              ? serachTextDoc
                              : ''
                          }
                          onChange={({ target: { value } }) => {
                            if (tabSelected === 'classes') {
                              setSerachTextClasses(value);
                              classfilter(value);
                            }

                            if (tabSelected === 'documents') {
                              setSerachTextDoc(value);
                              docFilter(value);
                            }
                            return false;
                          }}
                        />
                        <button type="button">
                          <i className="tecoIco">
                            <SearchIcon />
                          </i>
                        </button>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="navMenu">
                <ul>
                  <li
                    style={{ cursor: 'pointer' }}
                    className={tabSelected === 'overview' ? 'active' : ''}
                    onClick={() => {
                      setTabSelected('overview');
                    }}
                  >
                    <a>Overview</a>
                  </li>
                  <li
                    style={{ cursor: 'pointer' }}
                    className={tabSelected === 'classes' ? 'active' : ''}
                    onClick={() => {
                      setTabSelected('classes');
                      serachTextClasses && classfilter(serachTextClasses);
                    }}
                  >
                    <a>Classes</a>
                  </li>
                  <li
                    style={{ cursor: 'pointer' }}
                    className={tabSelected === 'documents' ? 'active' : ''}
                    onClick={(e) => {
                      setTabSelected('documents');
                      loadStudentDocuments({ studentId: userId });
                    }}
                  >
                    <a>Documents</a>
                  </li>
                  <li
                    style={{ cursor: 'pointer' }}
                    className={tabSelected === 'meetings' ? 'active' : ''}
                    onClick={() => {
                      setTabSelected('meetings');
                    }}
                  >
                    <a>Meetings & Notes</a>
                  </li>
                </ul>
              </div>
              {tabSelected === 'overview' && (
                <StudentOverview
                  studentSelectedInfo={studentSelectedInfo}
                  studentTaskList={studentTaskList}
                  academicStandingList={academicStandingList}
                  updateStudentList={updateStudentList}
                  goalProgress={goalProgress}
                  degreeAuditInfo={degreeAuditInfo}
                />
              )}
              {tabSelected === 'classes' && (
                <StudentClasses
                  data={classesData}
                  classesSorting={classesSorting}
                  sortedBy={sortedBy}
                />
              )}
              {tabSelected === 'documents' && (
                <StudentDocument
                  docIsLoading={docIsLoading}
                  studentDocuments={studentDocuments}
                />
              )}

              {tabSelected === 'meetings' && <StudentMeetings sId={userId} />}

              <div className="progress-quicklinkrow">
                {/* <div className="quicklinkBox">
                <h3 className="boxTitles">Quick Links</h3>
                <ul className="quicklinks">
                  <li>
                    <a href="#">Degree audit</a>
                  </li>

                  <li>
                    <a href="#">IGETC Certification</a>
                  </li>

                  <li>
                    <a href="#">CSU GE Certification</a>
                  </li>

                  <li>
                    <a href="#">AA/AS GE Certification</a>
                  </li>

                  <li>
                    <a href="#">Other Degree Options</a>
                  </li>

                  <li>
                    <a href="#">Career certificate</a>
                  </li>
                </ul>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <StudentContactModal
        show={showContactModal}
        showHideModal={showHideContactModal}
        contactInfo={studentSelectedInfo}
      />
    </>
  );
}

export default StudentView;
