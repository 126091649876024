import React from 'react';

function BackArrow() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35369 5.09282C7.50369 5.24283 7.58796 5.44628 7.58796 5.65842C7.58796 5.87056 7.50369 6.07401 7.35369 6.22402L2.26329 11.3144L7.35368 16.4048C7.50369 16.5548 7.58796 16.7583 7.58796 16.9704C7.58796 17.1825 7.50369 17.386 7.35369 17.536C7.20368 17.686 7.00023 17.7703 6.78809 17.7703C6.57595 17.7703 6.37249 17.686 6.22249 17.536L0.566495 11.88C0.416489 11.73 0.332215 11.5266 0.332215 11.3144C0.332215 11.1023 0.416489 10.8988 0.566495 10.7488L6.22249 5.09282C6.37249 4.94281 6.57595 4.85854 6.78809 4.85854C7.00023 4.85854 7.20368 4.94281 7.35369 5.09282Z"
        fill="#2E7BC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2949 11.3137C22.295 11.4189 22.2744 11.5231 22.2342 11.6204C22.194 11.7176 22.135 11.8059 22.0606 11.8803C21.9862 11.9547 21.8978 12.0137 21.8006 12.0539C21.7034 12.0941 21.5992 12.1147 21.494 12.1146L1.1324 12.1146C0.919987 12.1146 0.716278 12.0302 0.566082 11.88C0.415886 11.7298 0.331507 11.5261 0.331507 11.3137C0.331507 11.1013 0.415886 10.8976 0.566082 10.7474C0.716277 10.5972 0.919987 10.5128 1.1324 10.5128L21.494 10.5128C21.5992 10.5127 21.7034 10.5333 21.8006 10.5735C21.8978 10.6137 21.9862 10.6727 22.0606 10.7471C22.135 10.8215 22.194 10.9098 22.2342 11.0071C22.2744 11.1043 22.295 11.2085 22.2949 11.3137Z"
        fill="#2E7BC0"
      />
    </svg>
  );
}

export default BackArrow;
