import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  counsellorType,
  createDefaultMeetingSettings,
  getDefaultMeetingSettings,
  updateDefaultMeetingSettings,
} from '../modals';

function EditDefaultMeetingSettings({ show, showHideModal, save }) {
  const [editMode, setEditMode] = useState(false);
  const [counsellorTypes, setCounsellorTypes] = useState([]);
  const [apiResponse, setApiResponse] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const submit = handleSubmit((data) => {
    saveDefaultMeetingSettings(data);
  });

  const loadDefaultMeetingSettings = async () => {
    try {
      const result = await getDefaultMeetingSettings();
      result.meetingLink && setEditMode(true);
      const { counselorType, meetingLink, officeLocation, phoneNumber } =
        result;
      setValue('counselorType', counselorType);
      setValue('meetingLink', meetingLink);
      setValue('officeLocation', officeLocation);
      setValue('phoneNumber', phoneNumber);
    } catch (error) {
      console.log('load default meeting settings', error);
      setApiResponse('Data Loading Failed!!!');
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    }
  };

  const saveDefaultMeetingSettings = async (data) => {
    try {
      if (editMode) {
        await updateDefaultMeetingSettings(data);
      } else {
        await createDefaultMeetingSettings(data);
      }
      setApiResponse('Saved Successfully!!!');
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    } catch (error) {
      console.log('create default meeting settings', error.message);
      setApiResponse('Failed!!!');
      setTimeout(() => {
        setApiResponse('');
      }, 5000);
    }
  };

  const loadCounsellorType = async () => {
    try {
      const result = await counsellorType();
      setCounsellorTypes(result);
      loadDefaultMeetingSettings();
    } catch (error) {
      console.log('counsellortype', error);
      loadDefaultMeetingSettings();
    }
  };

  useEffect(() => {
    loadCounsellorType();
  }, []);

  return (
    <div className="edit-dflt-meet-setng">
      <div>
        <div className="SchedulecalanderModal">
          <ul className="studentInfo-labelInput">
            <li>
              <label>Office location</label>
              <input
                type="text"
                className="nborder"
                placeholder="Enter address"
                {...register('officeLocation', { required: 'required' })}
              />
              <span className="err">{errors.officeLocation?.message}</span>
            </li>

            <li>
              <label>Web Conference</label>
              <input
                type="text"
                className="nborder"
                placeholder="Enter URL"
                {...register('meetingLink', { required: 'required' })}
              />
              <span className="err">{errors.meetingLink?.message}</span>
            </li>

            <li>
              <label>Phone number</label>
              <input
                type="text"
                className="nborder"
                placeholder="Enter phone number"
                {...register('phoneNumber', { required: 'required' })}
              />
              <span className="err">{errors.phoneNumber?.message}</span>
            </li>
            <li>
              <label>Counsellor Type</label>
              <select
                className="nborder"
                {...register('counselorType', { required: 'required' })}
              >
                <option value="">select</option>
                {counsellorTypes.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </select>
              <span className="err">{errors.counselorType?.message}</span>
            </li>
          </ul>
          <div className="edit-stngs-btn">
            <button
              onClick={() => {
                console.log(errors);
                submit();
              }}
              className="btn blueroundedbtn"
            >
              Save
            </button>
          </div>
          <div>{apiResponse}</div>
        </div>
      </div>
    </div>
  );
}

export default EditDefaultMeetingSettings;
