import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format, subDays, addDays, differenceInMinutes } from 'date-fns';
import { useRef, useEffect } from 'react';
import { useState } from 'react';
import LeftArrow from '../components/svg/leftArrow';
import RightArrow from '../components/svg/rightArrow';
import MeetingInfoModal from '../dashBoard/modal/meetingInfoModal';
import { getMeeetingDetails } from '../modals';

function CalendarWide() {
  const calendarRef = useRef();
  const [headerMonths, setHeaderMonths] = useState({});
  const { next, prev } = headerMonths;
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [meetingsArray, setMeetingsArray] = useState([]);
  const [meetingData, setMeetingData] = useState([]);

  const [clickedMeeting, setClickedMeeting] = useState({});

  const showHideMeetingModal = (bool) => {
    setShowMeetingModal(bool);
    if (!bool) {
      const meetingsarray = [...meetingsArray];
      const newMeetingArr = [];
      meetingsarray.map((item) => {
        newMeetingArr.push({
          ...item,
          color: '',
          textColor: '',
        });
      });
      setMeetingsArray(newMeetingArr);
    }
  };

  // const showHideConfirmationModal = (bool) => {
  //   setShowConfirmationModal(bool);
  // };

  const getMeetingInfo = (meetingid) => {
    const [result] = meetingData.filter(({ meetingId }) => {
      return meetingId === parseInt(meetingid);
    });
    setClickedMeeting(result);
    console.log('result', result);
    showHideMeetingModal(true);
  };

  // const handleEvents = (events) => {};
  // const handleDateSelect = (selectInfo) => {
  //   let startDay = format(selectInfo.start, 'EEEE');
  //   let endDay = format(selectInfo.end, 'EEEE');
  //   if (startDay !== endDay) {
  //     let calendarApi = selectInfo.view.calendar;
  //     calendarApi.unselect();
  //     alert('cant select morethan one day');
  //     return false;
  //   }

  //   // let title = prompt('Please enter a new title for your event');
  //   let calendarApi = selectInfo.view.calendar;
  //   console.log(selectInfo);
  //   calendarApi.unselect(); // clear date selection

  //   // if (title) {
  //   calendarApi.addEvent({
  //     id: 'f1',
  //     title: 'Creating slot. . .',
  //     start: selectInfo.startStr,
  //     end: selectInfo.endStr,
  //     allDay: selectInfo.allDay,
  //   });
  //   // }
  //   splitAndSaveEvent(selectInfo.start, selectInfo.end);
  // };
  const handleEventClick = (clickInfo) => {
    const selectedId = clickInfo.event.id;
    const meetingsarray = [...meetingsArray];
    const newMeetingArr = [];
    meetingsarray.map((item) => {
      const { id } = item;
      newMeetingArr.push({
        ...item,
        color: parseInt(selectedId) === id ? '#1367B2' : '',
        textColor: parseInt(selectedId) === id ? 'white' : '',
      });
    });
    setMeetingsArray(newMeetingArr);
    getMeetingInfo(clickInfo.event.id);
  };

  const renderEventContent = ({ event: { start, end, title } }) => {
    const difference = differenceInMinutes(end, start);
    const starttime = format(start, 'hh:mm a');
    const endtime = format(end, 'hh:mm a');
    return (
      <>
        <p style={{ fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>
          {title}
          <span
            style={{ fontWeight: '400', fontSize: '10px', cursor: 'pointer' }}
          >
            {difference <= 30 && ' ' + starttime + ' - ' + endtime}
          </span>
        </p>
        {difference > 30 && (
          <p style={{ fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>
            {starttime + ' - ' + endtime}
          </p>
        )}
      </>
    );
  };
  const renderHeaderDates = (args) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{format(args.date, 'EEEE')}</span>
        <span>{format(args.date, 'd')}</span>
      </div>
    );
  };

  const calendarNavigation = (direction) => {
    const calendarApi = calendarRef.current.getApi();
    direction === 'prev' && calendarApi.prev();
    direction === 'next' && calendarApi.next();
    const prev = format(calendarApi.getDate(), 'MMMM');
    const next = format(addDays(calendarApi.getDate(), 6), 'MMMM');
    setHeaderMonths({ next, prev });
    loadMeetingDetails({
      startDate: format(calendarApi.getDate(), 'yyyy-MM-dd'),
      endDate: format(addDays(calendarApi.getDate(), 6), 'yyyy-MM-dd'),
    });
  };
  // const askConfirmation = async (bool) => {
  //   const calendarApi = calendarRef.current.getApi();
  //   const { startDay, endDay, startObject, endObject, starttime, endtime } =
  //     duration;
  //   if (bool) {
  //     try {
  //       await createOpenSlot({
  //         startDate: startDay,
  //         endDate: endDay,
  //         collegeId: USER_INFO.schoolId,
  //         counselorId: USER_INFO.id,
  //         endTime: endtime,
  //         startTime: starttime,
  //       });
  //       setCounter(counter_temp);
  //       calendarApi.getEventById('f1').remove();
  //       createMeetingArray(meeting_temp);
  //       setMeetingData(meeting_temp);
  //       return;
  //     } catch (error) {
  //       console.log('slot creation', error);
  //     }
  //   }
  //   setCounter(counter_temp);
  //   calendarApi.getEventById('f1').remove();
  // };

  // const splitAndSaveEvent = (start, end) => {
  //   const { hours, minutes } = intervalToDuration({
  //     start,
  //     end,
  //   });

  //   const date = format(start, 'yyyy-MM-dd');
  //   let starttime = start;
  //   let endtime = '';
  //   let j = counter;
  //   const meetingarr = [...meetingData];
  //   if (hours > 0) {
  //     for (let i = 1; i <= hours * 2; i++) {
  //       j = j + 1;
  //       starttime = endtime ? addMinutes(start, 30 * (i - 1)) : starttime;
  //       endtime = addMinutes(start, 30 * i);
  //       meetingarr.push({
  //         title: 'Open Slot',
  //         meetingId: j,
  //         date,
  //         start: format(starttime, 'HH:mm'),
  //         end: format(endtime, 'HH:mm'),
  //       });
  //     }
  //   }
  //   if (minutes > 0) {
  //     j = j + 1;
  //     meetingarr.push({
  //       title: 'Open Slot',
  //       meetingId: j,
  //       date,
  //       start: endtime ? format(endtime, 'HH:mm') : format(starttime, 'HH:mm'),
  //       end: endtime
  //         ? format(addMinutes(endtime, 30), 'HH:mm')
  //         : format(addMinutes(starttime, 30), 'HH:mm'),
  //     });
  //   }
  //   const duration = {
  //     startDay: format(start, 'yyyy-MM-dd'),
  //     endDay: format(end, 'yyyy-MM-dd'),
  //     starttime: format(start, 'HH:mm:ss'),
  //     endtime: format(end, 'HH:mm:ss'),
  //     startObject: start,
  //     endObject: end,
  //   };
  //   setMeetingInfoTemp({ meeting_temp: meetingarr, counter_temp: j, duration });
  //   showHideConfirmationModal(true);
  // };

  const createMeetingArray = (data) => {
    let meetingarr = [];
    if (data.length > 0) {
      data.map((item) => {
        const {
          meetingStartTime,
          meetingEndTime,
          meetingDate,
          meetingId,
          studentDetail = [],
        } = item;
        meetingarr.push({
          start: `${meetingDate}T${
            meetingStartTime.split(':')[0] +
            ':' +
            meetingStartTime.split(':')[1]
          }`,
          end: `${meetingDate}T${
            meetingEndTime.split(':')[0] + ':' + meetingEndTime.split(':')[1]
          }`,
          id: meetingId,
          title: studentDetail[0]?.fullName,
        });
      });
    }
    setMeetingsArray(meetingarr);
    console.log('meetingarr', meetingarr);
  };

  const loadMeetingDetails = async (data) => {
    try {
      const result = await getMeeetingDetails(data);
      setMeetingData(result);
      createMeetingArray(result);
    } catch (error) {
      console.log('meeting details', error);
    }
  };

  // const createOpenSlot = async (data) => {
  //   try {
  //     const result = await createCounsellorAvailability(data);
  //     return result;
  //   } catch (error) {
  //     console.log(error?.message || `create open slot ${error}`);
  //   }
  // };

  useEffect(() => {
    let timezonediv = document.getElementsByClassName(
      'fc-timegrid-axis-frame'
    )[0];
    timezonediv.innerHTML += '<span class="timezone-calendar">PDT<span>';
    const calendarApi = calendarRef.current.getApi();
    const next = format(calendarApi.getDate(), 'MMMM');
    let day = format(calendarApi.getDate(), 'EEEE');
    let decreaseBy =
      day === 'Sunday'
        ? 0
        : day === 'Monday'
        ? 1
        : day === 'Tuesday'
        ? 2
        : day === 'Wednesday'
        ? 3
        : day === 'Thursday'
        ? 4
        : day === 'Friday'
        ? 5
        : day === 'Saturday' && 6;

    const prev = format(subDays(calendarApi.getDate(), decreaseBy), 'MMMM');
    setHeaderMonths({ next, prev });
    loadMeetingDetails({
      startDate: format(
        subDays(calendarApi.getDate(), decreaseBy),
        'yyyy-MM-dd'
      ),
      endDate: format(
        addDays(calendarApi.getDate(), 6 - decreaseBy),
        'yyyy-MM-dd'
      ),
    });
  }, []);

  return (
    <>
      <div className="schedulerCalendarWide">
        <div className="schedulerCalanderHeader">
          <div className="title">Calendar</div>
          <div className="monthSelector">
            <span
              onClick={() => {
                calendarNavigation('prev');
              }}
            >
              <LeftArrow />
            </span>
            {`${prev !== next ? prev + ' - ' + next : ''}`}
            {`${prev === next ? prev : ''}`}
            <span
              onClick={() => {
                calendarNavigation('next');
              }}
            >
              <RightArrow />
            </span>
          </div>
        </div>
        <div className="calanderboxWrapper calendar-wide">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            slotMinTime="06:00:00"
            slotMaxTime="23:00:00"
            initialView="timeGridWeek"
            editable={false}
            selectable={false}
            // selectMirror={true}
            dayMaxEvents={false}
            // dayHeaders={false}
            allDaySlot={false}
            headerToolbar={false}
            dayHeaderFormat={{
              day: 'numeric',
              weekday: 'long',
              omitCommas: true,
            }}
            dayHeaderContent={renderHeaderDates}
            selectOverlap={false}
            weekends={true}
            height="auto"
            events={meetingsArray}
            // select={handleDateSelect}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            // eventsSet={handleEvents}
            eventBackgroundColor="#DBEDFF"
            eventBorderColor="transparent"
            eventTextColor="#1D5EA6"
          />
        </div>
      </div>
      {showMeetingModal && (
        <MeetingInfoModal
          show={showMeetingModal}
          showHideModal={showHideMeetingModal}
          meetingInfo={clickedMeeting}
          fromTitle="Calendar"
          fromLink="/calendar"
        />
      )}
      {/* {showConfirmationModal && ( */}
      {/* <SlotConfirmationModal
        show={showConfirmationModal}
        showHideModal={showHideConfirmationModal}
        duration={duration}
        confirmation={askConfirmation}
      /> */}
      {/* )} */}
    </>
  );
}

export default CalendarWide;
