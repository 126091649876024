import React from 'react';

function DownUpArrow({ rotateUp }) {
  return (
    <span className={rotateUp ? 'tecoicon' : 'tecoicon rotated'}>
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.5 12L12.9952 0.75H0.00480938L6.5 12Z" fill="#C4C4C4" />
      </svg>
    </span>
  );
}

export default DownUpArrow;
