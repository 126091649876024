import React from 'react';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import MenuIcon from '../components/svg/menuIcon';
import NoMeetingHeroImage from '../components/svg/noMeetingHeroImage';

function TodayStudents({ list, updateClickedMeeting, showHideMeetingModal }) {
  const history = useHistory();
  console.log(list);
  return (
    <div className="todaysStudents">
      <h3 style={{ marginTop: 25 }}>Today’s students</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Name</th>
            <th>Meeting topic</th>
            <th>Academic standing</th>
            <th>Notes</th>
            <th style={{ width: '50px' }}></th>
          </tr>
        </thead>
        <tbody>
          {list.length === 0 ? (
            <div className="noMeetingWrapper">
              <div className="nomeetingImage">
                <NoMeetingHeroImage width={200} height={200} />
              </div>
              <h3>
                No students scheduled today. Click on the calendar to create
                your first session!
              </h3>
            </div>
          ) : (
            list.map(
              (
                {
                  studentDetail,
                  meetingDetails,
                  academicProbation,
                  meetingReasonTypeDesc,
                  studentDetail: [{ academicStandings, studentId, fullName }],
                },
                index
              ) => (
                <tr>
                  <td>{index + 1}</td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push({
                        pathname: '/studentlist',
                        studentId,
                      });
                      localStorage.setItem('backlink_title_pico', 'Home');
                      localStorage.setItem('backLink_pico', '/home');
                    }}
                  >
                    {fullName}
                  </td>
                  <td>{meetingReasonTypeDesc}</td>
                  <td>
                    <ul className="academicStandingTags">
                      <li>
                        <span
                          className={
                            academicStandings === 1
                              ? 'good'
                              : academicStandings === 2
                              ? 'probation'
                              : 'dismissed'
                          }
                        >
                          {academicStandings === 1
                            ? 'Good'
                            : academicStandings === 2
                            ? 'Probation'
                            : 'Dismissed'}
                        </span>
                      </li>
                    </ul>
                  </td>
                  <td className="todays-meeting-details">
                    <span>{meetingDetails}</span>
                  </td>
                  <td
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      width: '50px',
                    }}
                    onClick={() => {
                      updateClickedMeeting(index);
                      showHideMeetingModal(true);
                    }}
                  >
                    <span className="todays-meeting-menu">
                      <MenuIcon
                        width={32}
                        color="#C7C7C7"
                        tooltip="Action"
                        place="bottom"
                      />
                    </span>
                  </td>
                </tr>
              )
            )
          )}
        </tbody>
      </table>
      <ReactTooltip
        backgroundColor="#E5E5E5"
        textColor="1f1f1f"
        effect="solid"
      />
    </div>
  );
}

export default TodayStudents;
