import React, { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import StudentDetailModal from './modal/studentDetailModal';
import StudentDocFullViewer from './modal/studentDocFullViewer';

function StudentOverview({
  studentSelectedInfo,
  updateStudentList,
  studentTaskList,
  academicStandingList,
  goalProgress: {
    major = {},
    overAll: { percentage, totalCompletedUnits, GPA, totalUnits } = {},
  },
  degreeAuditInfo: { loading: loadingPdf, url: pdfUrl, error: errorPdf },
  studentSelectedInfo: {
    academicStandings,
    goal,
    financialAidFlag,
    communityCollegeName,
    tags,
    enrollStatus,
    disabilityFlag,
    specialtyCounselingPrograms,
    GETrack,
  },
}) {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const showHideDetailModal = (bool) => {
    setShowDetailModal(bool);
  };
  const showHidePdfModal = (bool) => {
    setShowPdfModal(bool);
  };
  return (
    <>
      <div className="studentDetails-tasksRow">
        <div className="studentDetailsBox">
          <h3 className="boxTitles">Student details</h3>
          <ul className="studentDetailUl">
            <li className="firstRow lirow">
              <div className="goals">
                <p className="superTitle">Academic Goal</p>
                <h3>{goal || '--'}</h3>
              </div>
            </li>

            <li className="secondRow lirow">
              <div className="getrack">
                <p className="superTitle">Gen Ed Pattern</p>
                <h3>{GETrack || '--'}</h3>
              </div>
            </li>

            <li className="thirdRow lirow">
              <div className="communityCollege">
                <p className="superTitle">Current Community College</p>
                <h3>{communityCollegeName || '--'}</h3>
              </div>
            </li>

            <li className="fourthRow lirow">
              <div className="academicstanding">
                <p className="superTitle">Academic Standing</p>
                <ul className="academicStandingTags">
                  {Object.keys(academicStandings).length > 0 && (
                    <li>
                      <span
                        className={academicStandings.academicName.toLowerCase()}
                      >
                        {academicStandings.academicName}
                      </span>
                    </li>
                  )}
                  {Object.keys(academicStandings).length === 0 && '--'}
                </ul>
              </div>
            </li>
            <li className="fifthRow lirow">
              <div className="tags">
                <p className="superTitle">Tags</p>
                <ul className="genralTags">
                  {Object.keys(tags).map((key) => (
                    <li key={key}>
                      <span>{tags[key]}</span>{' '}
                    </li>
                  ))}

                  {Object.keys(tags).length === 0 && '--'}
                </ul>
              </div>
            </li>

            <li className="sixthRow lirow">
              <div className="financialaid">
                <p className="superTitle">Enrollment Status</p>
                <p>{enrollStatus || '--'}</p>
              </div>
            </li>
            <li className="seventhRow lirow">
              <div className="financialaid">
                <p className="superTitle">Disability Services</p>
                <p>{disabilityFlag ? 'Yes' : 'No'}</p>
              </div>
            </li>
            <li className="seventhRow lirow">
              <div className="financialaid">
                <p className="superTitle">Specialty Counseling Programs</p>
                <ul className="genralTags yellowspc">
                  {Object.keys(specialtyCounselingPrograms).map((key) => (
                    <li key={key}>
                      <span>{specialtyCounselingPrograms[key]}</span>{' '}
                    </li>
                  ))}

                  {Object.keys(specialtyCounselingPrograms).length === 0 &&
                    '--:--'}
                </ul>
              </div>
            </li>
          </ul>

          <div className="boxfooterd">
            <span
              className="text-center"
              onClick={() => {
                showHideDetailModal(true);
              }}
            >
              <button className="btn transparentbtn colorprimary">
                View More details
              </button>
            </span>
          </div>
        </div>
        <div className="studenTaskandprogressWrapper">
          <div className="progressBox">
            <div className="progresHeaderDiv">
              <h3 className="boxTitles">Progress</h3>
              <div className="selectwrapper">
                <div className="custom-goal">
                  <p>
                    Goal: <strong>{goal || '--'}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="pieanddatawrapper">
              <div className="piewrapper">
                <div
                  className="pie pieone"
                  style={{ height: '98px', width: '98px' }}
                >
                  {/* <PieChart /> old circile */}
                  <CircularProgressbar
                    value={percentage || 0}
                    text={`${percentage || '0'}%`}
                    strokeWidth={12}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      pathColor: '#BAE193',
                      textColor: '#212121',
                      trailColor: '#ebf7df',
                    })}
                  />
                </div>

                <div>
                  <p className="pieunittext">Units completed</p>
                  <p className="subdetails">
                    {totalCompletedUnits || '0'}/{totalUnits || '0'}
                  </p>
                </div>
              </div>
              <div className="datawrapper">
                <div className="databox done">
                  <h4>{GPA || '0'}</h4>
                  <p>Overall GPA</p>
                </div>
                <div className="databox dtwo">
                  <h4>{major.GPA || '0'}</h4>
                  <p>Major GPA</p>
                </div>
              </div>
            </div>

            <div className="footerbox">
              <button
                disabled={loadingPdf || errorPdf || !pdfUrl}
                onClick={() => {
                  showHidePdfModal(true);
                }}
                style={{
                  cursor:
                    loadingPdf || errorPdf || !pdfUrl ? 'text' : 'pointer',
                }}
                className={`btn transparentbtn  ${
                  loadingPdf || errorPdf || !pdfUrl
                    ? 'colorgray '
                    : 'colorprimary'
                }`}
              >
                {loadingPdf ||
                  errorPdf ||
                  (!pdfUrl && 'No degree audit') ||
                  ' View degree audit'}
              </button>
            </div>
          </div>

          <div className="studentTaskBox">
            <h3 className="boxTitles">
              TECO Tasks <span>{`(${studentTaskList?.length || 0})`}</span>
            </h3>

            {studentTaskList?.length > 0 ? (
              <ul className="tskUlLi">
                {studentTaskList.map(
                  ({ endDate, taskName, shortDesc, longDesc, taskStatus }) => (
                    <li key={taskName}>
                      <div>{taskName}</div>

                      <span>{taskStatus === 0 ? 'Pending' : 'Completed'}</span>
                    </li>
                  )
                )}
              </ul>
            ) : (
              'No Tasks'
            )}
          </div>
        </div>
      </div>
      {showDetailModal && (
        <StudentDetailModal
          show={showDetailModal}
          showHideModal={showHideDetailModal}
          detailInfo={studentSelectedInfo}
          academicStandingList={academicStandingList}
          updateStudentList={updateStudentList}
        />
      )}
      {showPdfModal && (
        <StudentDocFullViewer
          show={showPdfModal}
          showHideModal={showHidePdfModal}
          url={{ fileName: '', preSignedURL: pdfUrl }}
          selectedFileType="pdf"
        />
      )}
    </>
  );
}

export default StudentOverview;
