import React from 'react';

function Home() {
  return (
    <div className="pico-contentWrapper dashboardAlign">
      <h3>Welcome To Teco Advise</h3>
    </div>
  );
}

export default Home;
