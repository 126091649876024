import React from 'react';

function FileIconPvtNav({ color, tooltip, place }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      data-tip={tooltip}
      data-place={place}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.75V2H8.75C8.15326 2 7.58097 2.23705 7.15901 2.65901C6.73705 3.08097 6.5 3.65326 6.5 4.25V17.25C6.5 17.8467 6.73705 18.419 7.15901 18.841C7.58097 19.2629 8.15326 19.5 8.75 19.5H17.75C18.0455 19.5 18.3381 19.4418 18.611 19.3287C18.884 19.2157 19.1321 19.0499 19.341 18.841C19.5499 18.6321 19.7157 18.384 19.8287 18.111C19.9418 17.8381 20 17.5455 20 17.25V9H15.25C14.6533 9 14.081 8.76295 13.659 8.34099C13.2371 7.91903 13 7.34674 13 6.75Z"
        fill={color}
      />
      <path
        d="M14.5 6.75V2.5L19.5 7.5H15.25C15.0511 7.5 14.8603 7.42098 14.7197 7.28033C14.579 7.13968 14.5 6.94891 14.5 6.75Z"
        fill={color}
      />
      <path
        d="M5.503 4.62695C5.06357 4.78177 4.68301 5.06912 4.41381 5.44938C4.1446 5.82963 4.00002 6.28405 4 6.74995V17.254C4 18.5137 4.50045 19.7219 5.39124 20.6127C6.28204 21.5035 7.49022 22.004 8.75 22.004H15.244C16.224 22.004 17.057 21.378 17.366 20.504H8.75C7.88805 20.504 7.0614 20.1615 6.4519 19.5521C5.84241 18.9426 5.5 18.1159 5.5 17.254L5.503 4.62695Z"
        fill={color}
      />
    </svg>
  );
}
FileIconPvtNav.defaultProps = {
  color: '#999999',
};
export default FileIconPvtNav;
