import React from 'react';

function CalendarIconPvtNav({ color, tooltip, place }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      data-tip={tooltip}
      data-place={place}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6677 1.60615H14.4554V0H12.8492V1.60615H6.42462V0H4.81846V1.60615H1.60615C1.18031 1.60658 0.772022 1.77593 0.470901 2.07705C0.169781 2.37818 0.000425211 2.78646 0 3.21231V19.2738C0.000425211 19.6997 0.169781 20.108 0.470901 20.4091C0.772022 20.7102 1.18031 20.8796 1.60615 20.88H17.6677C18.0935 20.8796 18.5018 20.7102 18.8029 20.4091C19.1041 20.108 19.2734 19.6997 19.2738 19.2738V3.21231C19.2734 2.78646 19.1041 2.37818 18.8029 2.07705C18.5018 1.77593 18.0935 1.60658 17.6677 1.60615ZM1.60615 3.21231H4.81846V4.81846H6.42462V3.21231H12.8492V4.81846H14.4554V3.21231H17.6677V6.42461H1.60615V3.21231ZM1.60615 8.03077H5.62154V12.8492H1.60615V8.03077ZM12.0462 19.2738H7.22769V14.4554H12.0462V19.2738ZM12.0462 12.8492H7.22769V8.03077H12.0462V12.8492ZM13.6523 19.2738V14.4554H17.6677L17.6685 19.2738H13.6523Z"
        fill={color}
      />
    </svg>
  );
}
CalendarIconPvtNav.defaultProps = {
  color: '#8E8E8E',
};
export default CalendarIconPvtNav;
