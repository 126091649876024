import React from 'react';

function PhoneReceiverIcon({ color = '#BDBDBD', height = 12, width = 12 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.559395 1.89612L2.11034 0.346602C2.21975 0.236714 2.34979 0.149525 2.49299 0.0900444C2.63619 0.0305635 2.78973 -3.71645e-05 2.9448 3.38731e-08C3.26081 3.38731e-08 3.55789 0.123786 3.7807 0.346602L5.44961 2.01553C5.5595 2.12494 5.64669 2.25498 5.70617 2.39819C5.76565 2.54139 5.79625 2.69493 5.79621 2.85C5.79621 3.16602 5.67242 3.46311 5.44961 3.68592L4.22924 4.90631C4.5149 5.53596 4.91206 6.10877 5.40156 6.59709C5.88982 7.08777 6.46257 7.48639 7.09231 7.77379L8.31268 6.5534C8.42208 6.44351 8.55212 6.35632 8.69532 6.29684C8.83852 6.23736 8.99207 6.20676 9.14713 6.2068C9.46314 6.2068 9.76023 6.33058 9.98304 6.5534L11.6534 8.22087C11.7634 8.33048 11.8507 8.46076 11.9102 8.60423C11.9697 8.74769 12.0002 8.90149 12 9.0568C12 9.37282 11.8762 9.6699 11.6534 9.89272L10.1054 11.4408C9.75003 11.7976 9.25926 12 8.75539 12C8.64908 12 8.54714 11.9913 8.44665 11.9738C6.48358 11.6505 4.53652 10.6063 2.96518 9.03641C1.3953 7.46796 0.352601 5.52233 0.0249367 3.5534C-0.074091 2.95194 0.125421 2.33301 0.559395 1.89612Z"
        fill={color}
      />
    </svg>
  );
}

export default PhoneReceiverIcon;
